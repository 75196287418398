import { Button } from "@mui/material";
import { useIntl } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.scss";

export interface StyledButtonProps {
  title?: string;
  onClick?: () => void;
  size: sizeType;
  isDisabled?: boolean;
  buttonVariant: buttonVariantType;
  withLeftIcon?: React.ReactNode;
  withRightIcon?: React.ReactNode;
  withSmallIcon?: React.ReactNode;
  isSubmbit?: boolean;
  isLoading?: boolean;
}

type sizeType = "large" | "medium" | "small";
type buttonVariantType = "text" | "outlined" | "contained";

export const StyledButton = ({
  title = "",
  onClick,
  buttonVariant,
  isDisabled,
  size,
  withLeftIcon,
  withRightIcon,
  withSmallIcon,
  isSubmbit,
  isLoading = false,
}: StyledButtonProps) => {
  const intl = useIntl();

  const titleMessage = intl.formatMessage({ id: title });
  const checkForLeftIcon =
    withLeftIcon && size !== "small" && isLoading === false;
  const checkForRightIcon =
    withRightIcon && size !== "small" && isLoading === false;
  return (
    <Button
      startIcon={
        checkForLeftIcon && <div data-testid="left-icon">{withLeftIcon}</div>
      }
      disableRipple
      variant={buttonVariant}
      className={`${size} ${buttonVariant} ${isDisabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      endIcon={
        checkForRightIcon && <div data-testid="right-icon">{withRightIcon}</div>
      }
      type={isSubmbit ? "submit" : "button"}
    >
      {isLoading && size !== "small" ? (
        <div className="is-loaging-container">
          <CircularProgress size={20} color="inherit" />
          {titleMessage}
        </div>
      ) : (
        size !== "small" && titleMessage
      )}
      {isLoading && size === "small" ? (
        <div className="is-loaging-container small">
          <CircularProgress size={20} color="inherit" />
        </div>
      ) : (
        size === "small" && (
          <div className="small-icon-container" data-testid="small-icon">
            {withSmallIcon}
          </div>
        )
      )}
    </Button>
  );
};
