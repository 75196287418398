import { useEffect, useMemo } from "react";
import {
  getCategories,
  LegacyCategories,
  legacyMapping,
  RevisedCategories,
  revisedMapping,
} from "services/categories.service";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { PROCESS_STATE } from "./UserStore";

export interface StoreAsyncProcess<D = any> {
  state: PROCESS_STATE;
  data?: D;
}

export interface AsyncAction<S extends StoreAsyncProcess> {
  execute: (...params: any) => Promise<any>;
  clear: () => void;
  process: S;
}

export interface UserData {
  email?: string;
  first_name?: string;
  last_name?: string;
  referral_id?: string;
  isActive?: boolean;
}

interface CategoriesStoreI {
  legacyCategories: LegacyCategories;
  revisedCategories: RevisedCategories;

  getCategories_process: StoreAsyncProcess;
  getCategories_execute: (mappingType: string) => Promise<void>;
  getCategories_clear: () => any;

  mapLegacy_process: StoreAsyncProcess;
  mapLegacy_execute: (categories: LegacyCategories) => Promise<void>;
  mapLegacy_clear: () => any;

  mapRevised_process: StoreAsyncProcess;
  mapRevised_execute: (categories: RevisedCategories) => Promise<void>;
  mapRevised_clear: () => any;
}

export const useCategoriesStore = create<CategoriesStoreI>()(
  devtools(
    persist(
      (set, get) => ({
        legacyCategories: {
          knowledge: null,
          comprehension: null,
          application: null,
          analysis: null,
          synthesis: null,
          evaluation: null,
        },
        revisedCategories: {
          remember: null,
          understand: null,
          apply: null,
          analyze: null,
          evaluate: null,
          create: null,
        },
        getCategories_process: { state: PROCESS_STATE.IDLE },
        getCategories_clear: () =>
          set({ getCategories_process: { state: PROCESS_STATE.IDLE } }),
        getCategories_execute: async (mappingType: string) => {
          set({ getCategories_process: { state: PROCESS_STATE.EXECUTING } });
          try {
            const data = await getCategories(mappingType);
            if (mappingType === "revised") {
              set({
                revisedCategories: data.data as RevisedCategories,
              });
            } else {
              set({
                legacyCategories: {
                  knowledge: data.data.knowledge,
                  comprehension: data.data.comprehension,
                  application: data.data.application,
                  analysis: data.data.analysis,
                  synthesis: data.data.synthesis,
                  evaluation: data.data.evaluation
                }
              });
            }
            set({
              getCategories_process: { state: PROCESS_STATE.SUCCESS, data },
            });
          } catch (e: any) {
            set({
              getCategories_process: { state: PROCESS_STATE.FAILED, data: e },
            });
          }
        },
        mapLegacy_process: { state: PROCESS_STATE.IDLE },
        mapLegacy_clear: () =>
          set({ mapLegacy_process: { state: PROCESS_STATE.IDLE } }),
        mapLegacy_execute: async (categories: LegacyCategories) => {
          set({ mapLegacy_process: { state: PROCESS_STATE.EXECUTING } });
          try {
            await legacyMapping(categories);
            get().getCategories_execute("legacy");
            set({ mapLegacy_process: { state: PROCESS_STATE.SUCCESS } });
          } catch (e: any) {
            set({
              mapLegacy_process: { state: PROCESS_STATE.FAILED, data: e },
            });
          }
        },
        mapRevised_process: { state: PROCESS_STATE.IDLE },
        mapRevised_clear: () =>
          set({ mapRevised_process: { state: PROCESS_STATE.IDLE } }),
        mapRevised_execute: async (categories: RevisedCategories) => {
          set({ mapRevised_process: { state: PROCESS_STATE.EXECUTING } });
          try {
            await revisedMapping(categories);
            get().getCategories_execute("revised");
            set({
              mapRevised_process: { state: PROCESS_STATE.SUCCESS },
            });
          } catch (e: any) {
            set({
              mapRevised_process: { state: PROCESS_STATE.FAILED, data: e },
            });
          }
        },
      }),
      {
        name: "categories-storage",
      }
    )
  )
);

export const useMapLegacy = () => {
  const { mapLegacy, process, clear } = useCategoriesStore(
    (state) => ({
      mapLegacy: state.mapLegacy_execute,
      process: state.mapLegacy_process,
      clear: state.mapLegacy_clear,
    }),
    shallow
  );
  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );
  return useMemo(() => ({ mapLegacy, process }), [mapLegacy, process]);
};

export const useMapRevised = () => {
  const { mapRevised, process, clear } = useCategoriesStore(
    (state) => ({
      mapRevised: state.mapRevised_execute,
      process: state.mapRevised_process,
      clear: state.mapRevised_clear,
    }),
    shallow
  );
  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );
  return useMemo(() => ({ mapRevised, process }), [mapRevised, process]);
};

export const useGetCategories = () => {
  const { getCategories, process, clear } = useCategoriesStore(
    (state) => ({
      getCategories: state.getCategories_execute,
      process: state.getCategories_process,
      clear: state.getCategories_clear,
    }),
    shallow
  );
  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );
  return useMemo(() => ({ getCategories, process }), [getCategories, process]);
};
