import React, { useState } from "react";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { ArrowSquareOut, Info } from "phosphor-react";
import { useIntl } from "react-intl";
import "./styles.scss";
import { useTierReferralsStore } from "store/TierReferralsStore";
import { Elevations } from "components/Atoms/Elevations/Elevations";
import { getColor } from "theme/colors";
import { StyledButton } from "components/Atoms/StyledButton/StyledButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
interface QuotaCounterProps {
  counter: number;
}

export const QuotaCounter = ({ counter }: QuotaCounterProps) => {
  const intl = useIntl();
  const [showTooltip, setShowTooltip] = useState(false);
  const { tierLevel, predictionsLeft, isUnlimited } = useTierReferralsStore(
    (state) => state.tierDataUser
  );
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const message = () => {
    return (
      <Elevations variant="rectangle-2" className="tooltip-content">
        <StyledText color="grey50" variant="body-small">
          {intl.formatMessage(
            { id: "quota.counter.tooltip.message" },
            { tierLevel: tierLevel, predictionsLeft: predictionsLeft }
          )}
        </StyledText>
        <div className="tooltip-footer-container">
          <StyledButton
            onClick={() => navigate(ROUTES.TIERSANDREFERRALS)}
            withRightIcon={
              <ArrowSquareOut
                color={getColor("secondary100")}
                width={15}
                height={15}
              />
            }
            buttonVariant="text"
            size="medium"
            title={intl.formatMessage({ id: "quota.counter.title" })}
          />
        </div>
      </Elevations>
    );
  };
  return (
    <div className="quota-counter-container" role={"contentinfo"}>
      <div className="quota-counter-description-container">
        <StyledText variant="body-large">
          {intl.formatMessage({ id: "quota.counter.description" })}
        </StyledText>
        <StyledText color="secondary100" variant="title-large">
          {isUnlimited
            ? intl.formatMessage({ id: "quota.counter.unlimited" })
            : counter}
        </StyledText>
      </div>
      {!isUnlimited ? (
        <div
          className="tooltip-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Info data-testid="svg-info" size={19.5} color="grey" />
          {showTooltip && message()}
        </div>
      ) : null}
    </div>
  );
};
