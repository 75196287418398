import { useEffect, useMemo } from "react";
import { sendEmailToRecoverPassword, setNewPassword } from "services/recoverPassword.service";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { PROCESS_STATE, StoreAsyncProcess } from "./UserStore";

interface NewPasswordStoreI {
  email: string;
  setNewPassword_process: StoreAsyncProcess
  setNewPassword_execute: (newPassword: string, token: string, handleSuccess: () => void) => Promise<void>
  setNewPassword_clear: () => any
  getEmailToken_process: StoreAsyncProcess
  getEmailToken_execute: (emailUser: string) => Promise<void>
  getEmailToken_clear: () => any
}
const useNewPasswordStore = create<NewPasswordStoreI>()(devtools(persist(
  (set, get) => ({
    email: "",
    getEmailToken_clear: () => set({
      getEmailToken_process: { state: PROCESS_STATE.IDLE },
    }),
    getEmailToken_execute: async (emailUser) => {
      set({
        getEmailToken_process: { state: PROCESS_STATE.EXECUTING }
      })
      try {
        await sendEmailToRecoverPassword(emailUser)
        set({ email: emailUser })
        set({ getEmailToken_process: { state: PROCESS_STATE.SUCCESS } })
      } catch (error: any) {
        if (error.request.status === 404)
          set({ getEmailToken_process: { state: PROCESS_STATE.FAILED, data: error.request.status } })
      }
    },



    setNewPassword_process: { state: PROCESS_STATE.IDLE },
    setNewPassword_clear: () => set({
      getEmailToken_process: { state: PROCESS_STATE.IDLE },
    }),
    setNewPassword_execute: async (newPassword, token, handleSuccess) => {
      set({
        getEmailToken_process: { state: PROCESS_STATE.EXECUTING }
      })
      try {
        await setNewPassword({ email: get().email, new_password: newPassword, update_token: token })
        set({ getEmailToken_process: { state: PROCESS_STATE.SUCCESS }, email: "" })
        handleSuccess()
      } catch (error: any) {
        set({ getEmailToken_process: { state: PROCESS_STATE.FAILED, data: error } })
      }
    },
    getEmailToken_process: { state: PROCESS_STATE.IDLE }
  }),
  {
    name: "reset-storage"
  }
)))

export const useSendNewPasswordStore = () => {
  const { clear, process, sendEmailForNewPassword } = useNewPasswordStore((state) => ({
    sendEmailForNewPassword: state.getEmailToken_execute,
    clear: state.getEmailToken_clear,
    process: state.getEmailToken_process,
  }), shallow)
  useEffect(() => () => { clear() }, [clear])
  return useMemo(() => ({ sendEmailForNewPassword, process }), [sendEmailForNewPassword, process])
}

export const useSetNewPassword = () => {
  const { clear, process, setNewPassword } = useNewPasswordStore((state) => ({
    setNewPassword: state.setNewPassword_execute,
    process: state.setNewPassword_process,
    clear: state.setNewPassword_clear
  }))
  useEffect(() => () => { clear() }, [clear])
  return useMemo(() => ({ process, setNewPassword }), [process, setNewPassword])
}