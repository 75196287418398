import { StyledTextFieldProps } from "components/Atoms/StyledTextField/index"
import { Controller } from "react-hook-form"
import React from "react"
import {
  justCharsAndNumbersString,
  justCharsAndNumbersStringWithHyphen,
  justCharsAndNumbersStringWithSpaces,
  justNumbersString,
  noRepeatSpaces,
  noSpecialCharsString,
} from "utils/helpers/app.helper"

interface TextInputComponentPropsHoc extends StyledTextFieldProps {
  name: string
  control: any
  blockSpecialChars?: boolean
  justNumbers?: boolean
  justCharsAndNumbers?: boolean
  justCharsAndNumbersWithSpaces?: boolean
  justCharsAndNumbersWithHyphen?: boolean
}

export const withControllerInput = (
  WrappedComponent: (props: StyledTextFieldProps) => JSX.Element
) => {
  const WithControllerInput = (props: TextInputComponentPropsHoc) => {
    const {
      name,
      control,
      blockSpecialChars,
      justNumbers,
      justCharsAndNumbers,
      justCharsAndNumbersWithSpaces,
      justCharsAndNumbersWithHyphen,
    } = props
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          const fieldValue = noRepeatSpaces(value)
          return (
            <WrappedComponent
              onChange={onChange}
              value={
                blockSpecialChars
                  ? noSpecialCharsString(fieldValue)
                  : justNumbers
                  ? justNumbersString(fieldValue)
                  : justCharsAndNumbers
                  ? justCharsAndNumbersString(fieldValue)
                  : justCharsAndNumbersWithSpaces
                  ? justCharsAndNumbersStringWithSpaces(fieldValue)
                  : justCharsAndNumbersWithHyphen
                  ? justCharsAndNumbersStringWithHyphen(fieldValue)
                  : fieldValue
              }
              onBlur={onBlur}
              {...props}
            />
          )
        }}
      />
    )
  }
  return WithControllerInput
}
