import { Controller } from "react-hook-form"
import React from "react"
import { InputSelectProps } from "components/Atoms/InputSelect"

interface SelectComponentPropsHoc extends InputSelectProps {
  name: string
  control: any
}

export const withControllerSelect = (
  WrappedComponent: (props: InputSelectProps) => JSX.Element
) => {
  const WithControllerSelect = (props: SelectComponentPropsHoc) => {
    const { name, control } = props
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <WrappedComponent
              onChange={onChange}
              value={value}
              // onBlur={onBlur}
              {...props}
            />
          )
        }}
      />
    )
  }
  return WithControllerSelect
}
