import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "components/Atoms/Form";
import { emailRecoverPassword } from "utils/validationSchemas/validationSchema";
import { withControllerInput } from "utils/hocs/withControllerInput";
import { StyledTextField } from "components/Atoms/StyledTextField";
import { Elevations } from "components/Atoms/Elevations/Elevations";
import "./styles.scss";
import { StyledButton } from "components/Atoms/StyledButton/StyledButton";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { useIntl } from "react-intl";
export interface RecoverPasswordFormModel {
  email: string;
}

interface RecoverPasswordFormProps {
  onSubmit: (data: RecoverPasswordFormModel) => void;
  isSent: boolean;
  error: boolean;
}

const ControllerTextInput = withControllerInput(StyledTextField);

export const RecoverPasswordForm = ({
  onSubmit,
  isSent,
  error,
}: RecoverPasswordFormProps) => {
  const intl = useIntl();
  const methods = useForm<RecoverPasswordFormModel>({
    mode: "onChange",
    resolver: yupResolver(emailRecoverPassword),
  });
  const { control, formState } = methods;
  const { isValid } = formState;

  return (
    <div>
      <Elevations
        className="recover-password-form-container"
        variant="rectangle-1"
      >
        <Form
          methods={methods}
          className="recover-password-form"
          onSubmit={onSubmit}
        >
          <ControllerTextInput
            name="email"
            id="email-input"
            label="forms.login.email"
            control={control}
            required
            fullWidth
          />
          {isSent && (
            <StyledText color="primary100">
              {intl.formatMessage({ id: "recover.password.correct.email" })}
            </StyledText>
          )}
          {error && (
            <StyledText color="primary100">
              {intl.formatMessage({ id: "recover.password.error.email" })}
            </StyledText>
          )}
          <StyledButton
            buttonVariant="contained"
            size="large"
            isSubmbit
            title="button.continue"
            isDisabled={isValid && !isSent ? false : true}
          />
        </Form>
      </Elevations>
    </div>
  );
};
