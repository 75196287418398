/* eslint-disable camelcase */
import React from "react"

interface IframeProps {
  content: any
}
export const Iframe = ({ content }: IframeProps) => {
  const writeHTML = (frame: any) => {
    if (!frame) {
      return
    }
    const doc = frame.contentDocument
    doc.open()
    doc.write(content)
    doc.close()
    frame.style.width = "100%"
    frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`
  }
  return (
    <iframe
      title="mainframe"
      src=""
      scrolling="yes"
      frameBorder="0"
      ref={writeHTML}
    />
  )
}

