import {
  RecoverPasswordForm,
  RecoverPasswordFormModel,
} from "components/Molecules/Forms/RecoverPasswordForm/RecoverPasswordForm"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import React from "react"
import "./styles.scss"
import Img from "components/Atoms/Img"
import { ROUTES } from "../../../constants"
import { useIntl } from "react-intl"
import { useSendNewPasswordStore } from "store/NewPasswordStore"
import { PROCESS_STATE } from "store/UserStore"

export const RecoverPasswordContainer = () => {
  const { process, sendEmailForNewPassword } = useSendNewPasswordStore()
  const intl = useIntl()

  const recoverPasswordSubmit = async (data: RecoverPasswordFormModel) => {
    sendEmailForNewPassword(data.email)
  }

  return (
    <div className="recover-password-container">
      <Img src={"/images/main_logo.png"} alt="logo" />
      <StyledText
        className={"recover-password-title"}
        variant="title-large"
        color="primary100"
        align="center">
        {intl.formatMessage({ id: "forms.recover.password.title" })}
      </StyledText>
      <RecoverPasswordForm
        error={process.state === PROCESS_STATE.FAILED}
        isSent={process.state === PROCESS_STATE.SUCCESS}
        onSubmit={recoverPasswordSubmit}
      />
      <StyledText
        className={"back-to-login-text"}
        To={ROUTES.LOGIN}
        link
        variant="label-large"
        align="center"
        color="primary100">
        {intl.formatMessage({ id: "back.to.login" })}
      </StyledText>
    </div>
  )
}
