import { useEffect, useMemo } from "react";
import { getTierInformationMe, getUsageOfTiers, getTierInformationGeneral, TiersInformationResponse } from "services/tiers.service";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { PROCESS_STATE, StoreAsyncProcess } from "./UserStore";

interface TierDataI {
  isUnlimited?: boolean;
  referralBonusDiffDomain?: number;
  referralBonusSameDomain?: number;
  canBatch?: boolean;
  tierLevel?: string;
  predictionsLeft?: number;
  canDownload?: boolean;
  referralCount: number;
  predictionsQuota?: number;
}

interface TierReferralsStoreI {
  tierDataUser: TierDataI;
  tierDataInformation: TiersInformationResponse[]
  getTierDataUser_process: StoreAsyncProcess;
  getTierDataUser_execute: () => Promise<void>;
  getTierDataUser_clear: () => any;
  getTierDataInformation_execute: () => Promise<void>
  getTierDataInformation_process: StoreAsyncProcess;
  getTierDataInformation_clear: () => any;

}

export const useTierReferralsStore = create<TierReferralsStoreI>()(
  devtools(
    persist(
      (set, get) => ({
        tierDataUser: {
          canBatch: false,
          canDownload: false,
          predictionsLeft: 0,
          tierLevel: "",
          referralCount: 0,
          predictionsQuota: 0,
        },
        tierDataInformation: [],
        getTierDataUser_clear: () =>
          set({
            getTierDataUser_process: { state: PROCESS_STATE.IDLE },
          }),
        getTierDataUser_execute: async () => {
          set({
            getTierDataUser_process: { state: PROCESS_STATE.EXECUTING },
          });
          try {
            const { data } = await getTierInformationMe();
            const { data: dataUsageOfTiers } = await getUsageOfTiers();
            set({
              tierDataUser: {
                isUnlimited: data.is_unlimited_predictions,
                referralBonusDiffDomain: data.referral_bonus_diff_domain,
                referralBonusSameDomain: data.referral_bonus_same_domain,
                canBatch: data.can_batch,
                canDownload: data.can_download,
                predictionsLeft: dataUsageOfTiers.available_predictions,
                tierLevel: data.display_name,
                referralCount: dataUsageOfTiers.referral_count,
                predictionsQuota:
                  dataUsageOfTiers.available_predictions +
                  dataUsageOfTiers.used_predictions,
              },
            });
            set({ getTierDataUser_process: { state: PROCESS_STATE.SUCCESS } });
          } catch (error: any) {
            set({
              getTierDataUser_process: { state: PROCESS_STATE.FAILED, data: error },
            });
          }
        },
        getTierDataUser_process: { state: PROCESS_STATE.IDLE },
        getTierDataInformation_process: { state: PROCESS_STATE.IDLE },
        getTierDataInformation_execute: async () => {
          set({ getTierDataInformation_process: { state: PROCESS_STATE.EXECUTING } })
          try {
            const {data} = await getTierInformationGeneral()
            set({ tierDataInformation: data })
            set({ getTierDataInformation_process: { state: PROCESS_STATE.SUCCESS } })
          } catch (error: any) {
            set({ getTierDataInformation_process: { state: PROCESS_STATE.FAILED, data: error } })
          }
        },
        getTierDataInformation_clear: () => {
          set({ getTierDataInformation_process: { state: PROCESS_STATE.IDLE } })
        }
      }),
      {
        name: "tier-storage",
      }
    )
  )
);

export const useTierReferralMe = () => {
  const { getTierDataUser, clear, process } = useTierReferralsStore(
    (state) => ({
      getTierDataUser: state.getTierDataUser_execute,
      process: state.getTierDataUser_process,
      clear: state.getTierDataUser_clear,
    }),
    shallow
  );
  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );
  return useMemo(
    () => ({ getTierDataUser, process }),
    [getTierDataUser, process]
  );
};

export const useTierReferralInformation = () => {
  const { getTierDataInformation, clear, process } = useTierReferralsStore((state) => ({
    getTierDataInformation: state.getTierDataInformation_execute,
    clear: state.getTierDataInformation_clear,
    process: state.getTierDataInformation_process
  }),
    shallow)
  useEffect(() => () => {
    clear()
  }, [clear])
  return useMemo(() => ({ getTierDataInformation, process }), [getTierDataInformation, process])
}
