import { StyledButton } from "components/Atoms/StyledButton/StyledButton"
import { StyledModal } from "components/Atoms/StyledModal"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import { useModalStore } from "store/ModalStore"
import React from "react"
import "./styles.scss"
import { useIntl } from "react-intl"
import { useUserStore } from "store/UserStore"
export const ExpiredSessionModal = () => {
  const intl = useIntl()
  const { setBooleanSessionExpiredModal, valueSessionExpireModal } = useModalStore()
  const { logout } = useUserStore()
  return (
    <div>
      <StyledModal
        onClose={() => {
          setBooleanSessionExpiredModal(false)
          logout()
        }}
        open={valueSessionExpireModal}
        title={intl.formatMessage({ id: "session.expired.title" })}
        width="315px">
        <div className="session-expired-description">
          <StyledText align="center">
            {intl.formatMessage({ id: "session.expired.description" })}
          </StyledText>
          <StyledButton
            size="large"
            buttonVariant="contained"
            title="modal.session.expired.ok"
            onClick={() => {
              setBooleanSessionExpiredModal(false)
              logout()
            }}
          />
        </div>
      </StyledModal>
    </div>
  )
}
