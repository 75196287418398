import React from "react";
import { Checkbox } from "@mui/material";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import "./styles.scss";

export interface CheckBoxProps {
  value?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  onClick?: () => void;
  defaultChecked?: boolean;
  labelId?: string;
  labelContent?: React.ReactNode;
  isDisabled?: boolean;
  indeterminate?: boolean;
  name: string;
  required?: boolean;
}

export const StyledCheckBox = ({
  value,
  onChange,
  onBlur,
  onClick,
  defaultChecked,
  labelId,
  isDisabled,
  indeterminate,
  name,
  labelContent,
  required,
}: CheckBoxProps) => {
  const customClassName = `${isDisabled ? "disabled" : ""}`;
  return (
    <div className="styledcheckbox-container">
      <Checkbox
        className={customClassName}
        checked={value}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        defaultChecked={defaultChecked}
        disabled={isDisabled}
        indeterminate={indeterminate}
        name={name}
        required={required}
      />
      {labelId ? (
        <StyledText variant="label-medium" align="left" color="natural-black">
          {labelId}
        </StyledText>
      ) : (
        labelContent
      )}
    </div>
  );
};
