import { StyledText } from "components/Atoms/StyledText/StyledText"
import { SlidersHorizontal } from "phosphor-react"
import React from "react"
import "./styles.scss"
import { getColor } from "theme/colors"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../../constants"

export const CustomizeCategoriesButton = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  return (
    <div
      className="customize-categories-container"
      onClick={() => navigate(`${ROUTES.CUSTOMIZE}`)}>
      <SlidersHorizontal color={getColor("secondary100")} />
      <StyledText variant="label-medium" color="secondary100">
        {intl.formatMessage({ id: "customize.categories.button.title" })}
      </StyledText>
    </div>
  )
}
