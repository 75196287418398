import { StyledText } from "components/Atoms/StyledText/StyledText"
import {
  NewPasswordForm,
  NewPasswordFormModel,
} from "components/Molecules/Forms/NewPasswordForm/NewPasswordForm"
import React from "react"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import Img from "components/Atoms/Img"
import { useIntl } from "react-intl"
import { ROUTES } from "../../../constants"
import { useSetNewPassword } from "store/NewPasswordStore"

export const NewPasswordContainer = () => {
  const { setNewPassword } = useSetNewPassword()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get("token")
  const intl = useIntl()
  const navigate = useNavigate()

  const handleSuccess = () => {
    navigate(ROUTES.HOME, { replace: true })
  }

  const newPasswordSubmit = (data: NewPasswordFormModel) => {
    setNewPassword(data.password, token!, handleSuccess)
  }
  return (
    <div className="new-password-container">
      <Img src={"/images/main_logo.png"} alt="logo" />
      <StyledText
        variant="title-large"
        color="primary100"
        align="center"
        className={"new-password-title"}>
        {intl.formatMessage({ id: "screens.newpassword.title" })}
      </StyledText>
      <NewPasswordForm onSubmit={newPasswordSubmit} />
    </div>
  )
}
