import { ArrowLeft } from "phosphor-react";
import { useIntl } from "react-intl";
import { Link, To } from "react-router-dom";
import { getColor } from "theme/colors";
import { ColorSchemaType } from "theme/types";
import { StyledText } from "../StyledText/StyledText";
import "./styles.scss";

interface GoBackProps {
  color: ColorSchemaType;
  stringText: string;
  to: To;
}

export const GoBack = ({ color, stringText, to }: GoBackProps) => {
  const intl = useIntl();
  return (
    <Link className="goBackDiv hoverElement" to={to}>
      <ArrowLeft size={20} color={getColor(color)} className="marginArrow" />
      <StyledText
        color={color}
        variant="label-large"
        className={"hoverElement"}
        underline
      >
        {intl.formatMessage({ id: stringText })}
      </StyledText>
    </Link>
  );
};
