import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { predictLegacyQuestion, predictLime, predictRevisedQuestion, sendFeedback } from "services/predicts.service";
import { useEffect, useMemo } from "react";
import { PROCESS_STATE, StoreAsyncProcess } from './UserStore'

export interface PredictLegacyResponse {
  analysis: number;
  application: number;
  comprehension: number;
  evaluation: number;
  knowledge: number;
  synthesis: number;

}

export interface PredictRevisedResponse {
  remember: number;
  understand: number;
  apply: number;
  analyze: number;
  evaluate: number;
  create: number;
}

export interface ThumbsUpAndDownI {
  open: boolean
  clicked: boolean
  thumbsUp: boolean | null
  success: boolean
  thumbsDown: boolean
}

interface PredictState {
  htmlLegacy: any
  htmlRevised: any
  prediction_ruuid: string
  predictionLegacy_mapped: string,
  predictionRevised_mapped: string
  legacy: PredictLegacyResponse[]
  revised: PredictRevisedResponse[]
  predictLime_process: StoreAsyncProcess
  predictLime_execute: (question: string, taxonomy: "original" | "revised", handleSuccess: () => void) => Promise<void>
  predictLime_clear: () => any

  predictLegacy_process: StoreAsyncProcess
  predictLegacy_execute: (question: string, handleSuccess: () => void) => Promise<void>
  predictLegacy_clear: () => any

  predictRevised_process: StoreAsyncProcess
  predictRevised_execute: (question: string, handleSuccess: () => void) => Promise<void>
  predictRevised_clear: () => any

  clean_predictLegacy: () => void
  clean_predictRevised: () => void

  sendFeedback_process: StoreAsyncProcess,
  sendFeedback_execute: (is_positive: boolean, correct_classification: string, comment: string, prediction_ruuid: string) => Promise<void>
  sendFeedback_clear: () => any

  thumbsUpAndDownLegacy: ThumbsUpAndDownI
  thumbsUpAndDownRevised: ThumbsUpAndDownI
  setTrue_thumbsUpAndDownLegacy: () => void
  setFalse_thumbsUpAndDownLegacy: () => void
  setTrue_thumbsUpAndDownRevised: () => void
  setFalse_thumbsUpAndDownRevised: () => void
  setOpen_thumbsUpAndDownLegacy: () => void
  setOpen_thumbsUpAndDownRevised: () => void
  categoryLegacy: string
  categoryRevised: string
  setCategoryLegacy_execute: (category: string) => void
  setCategoryRevised_execute: (category: string) => void

}

export const usePredictsStore = create<PredictState>()(
  devtools(
    persist(
      (set, get) => ({
        htmlLegacy: "",
        htmlRevised: "",
        prediction_ruuid: "",
        categoryLegacy: "",
        categoryRevised: "",
        predictionLegacy_mapped: "",
        predictionRevised_mapped: "",
        thumbsUpAndDownLegacy: {
          open: false,
          clicked: false,
          thumbsUp: null,
          success: false,
          thumbsDown: false,
        }, thumbsUpAndDownRevised: {
          open: false,
          clicked: false,
          thumbsUp: null,
          success: false,
          thumbsDown: false,
        },
        legacy: [{
          knowledge: 0,
          comprehension: 0,
          application: 0,
          analysis: 0,
          synthesis: 0,
          evaluation: 0,
        }],
        revised: [{
          remember: 0,
          understand: 0,
          apply: 0,
          analyze: 0,
          evaluate: 0,
          create: 0,
        }],
        clean_predictLegacy: () => set({
          legacy: [{ knowledge: 0, comprehension: 0, application: 0, analysis: 0, synthesis: 0, evaluation: 0 }], htmlLegacy: null, prediction_ruuid: "", thumbsUpAndDownLegacy: {
            open: false,
            clicked: false,
            thumbsUp: null,
            success: false,
            thumbsDown: false,
          },
          categoryLegacy: "",
          predictionLegacy_mapped: ""
        }),
        clean_predictRevised: () => set({
          revised: [{ analyze: 0, apply: 0, create: 0, evaluate: 0, remember: 0, understand: 0 }], htmlRevised: null, prediction_ruuid: "", thumbsUpAndDownRevised: {
            open: false,
            clicked: false,
            thumbsUp: null,
            success: false,
            thumbsDown: false,
          },
          categoryRevised: "",
          predictionRevised_mapped: ""
        }),
        predictLime_clear: () => set({
          predictLime_process: { state: PROCESS_STATE.IDLE }
        }),
        predictLime_execute: async (question, taxonomy, handleSuccess) => {
          set({
            predictLime_process: { state: PROCESS_STATE.EXECUTING }
          })
          try {
            const { data } = await predictLime(question)
            taxonomy === "original" ? set({ htmlLegacy: data }) : set({ htmlRevised: data })
            set({ predictLime_process: { state: PROCESS_STATE.SUCCESS } })
            handleSuccess()
          } catch (error: any) {
            set({ predictLime_process: { state: PROCESS_STATE.FAILED, data: error } })
          }
        },
        predictLime_process: { state: PROCESS_STATE.IDLE },
        predictLegacy_clear: () => set({ predictLegacy_process: { state: PROCESS_STATE.IDLE } }),
        predictLegacy_execute: async (question, handleSuccess) => {
          set({
            predictLegacy_process: { state: PROCESS_STATE.EXECUTING }
          })
          get().clean_predictLegacy()
          try {
            const { data } = await predictLegacyQuestion(question)
            const results = data.data.results
            const newLegacyArray: PredictLegacyResponse[] = [
              {
                knowledge: results.knowledge,
                comprehension: results.comprehension,
                application: results.application,
                analysis: results.analysis,
                synthesis: results.synthesis,
                evaluation: results.evaluation,
              }
            ]
            set({ prediction_ruuid: data.data.results.prediction_ruuid })
            set({ predictionLegacy_mapped: data.data.results.prediction_mapped })
            set({ legacy: newLegacyArray })
            handleSuccess()
            set({ predictLegacy_process: { state: PROCESS_STATE.SUCCESS } })
          } catch (error: any) {
            set({ predictLegacy_process: { state: PROCESS_STATE.FAILED, data: error } })
          }
        },
        predictLegacy_process: { state: PROCESS_STATE.IDLE },
        predictRevised_clear: () => set({ predictRevised_process: { state: PROCESS_STATE.IDLE } }),
        predictRevised_execute: async (question, handleSuccess) => {
          set({
            predictRevised_process: { state: PROCESS_STATE.EXECUTING }
          })
          get().clean_predictRevised()
          try {
            const { data } = await predictRevisedQuestion(question)
            const results = data.data.results
            const newRevisedArray: PredictRevisedResponse[] = [
              {
                remember: results.remember,
                understand: results.understand,
                apply: results.apply,
                analyze: results.analyze,
                evaluate: results.evaluate,
                create: results.create,
              },
            ]
            set({ predictionRevised_mapped: data.data.results.prediction_mapped })
            set({ prediction_ruuid: data.data.results.prediction_ruuid })
            set({ revised: newRevisedArray })
            handleSuccess()
            set({ predictRevised_process: { state: PROCESS_STATE.SUCCESS } })
          } catch (error: any) {
            set({ predictRevised_process: { state: PROCESS_STATE.FAILED, data: error } })
          }
        },
        predictRevised_process: { state: PROCESS_STATE.IDLE },
        sendFeedback_execute: async (is_positive, correct_classification, comment, prediction_ruuid) => {
          set({ sendFeedback_process: { state: PROCESS_STATE.EXECUTING } })
          try {
            await sendFeedback(is_positive, correct_classification, comment, prediction_ruuid)
            set({ sendFeedback_process: { state: PROCESS_STATE.SUCCESS } })
          } catch (error) {
            set({ sendFeedback_process: { state: PROCESS_STATE.FAILED, data: error } })

          }
        },
        sendFeedback_process: { state: PROCESS_STATE.IDLE },
        sendFeedback_clear: () => set({ sendFeedback_process: { state: PROCESS_STATE.IDLE } }),
        setFalse_thumbsUpAndDownLegacy: () => set({
          thumbsUpAndDownLegacy: {
            success: true,
            open: true,
            clicked: true,
            thumbsUp: false,
            thumbsDown: true
          }
        }),
        setTrue_thumbsUpAndDownLegacy: () => set({
          thumbsUpAndDownLegacy: {
            clicked: true,
            open: false,
            success: true,
            thumbsUp: true,
            thumbsDown: false,
          }
        }),
        setFalse_thumbsUpAndDownRevised: () => set({
          thumbsUpAndDownRevised: {
            success: true,
            open: true,
            clicked: true,
            thumbsUp: false,
            thumbsDown: true
          }
        }),
        setTrue_thumbsUpAndDownRevised: () => set({
          thumbsUpAndDownRevised: {
            clicked: true,
            open: false,
            success: true,
            thumbsUp: true,
            thumbsDown: false,
          }
        }),
        setOpen_thumbsUpAndDownLegacy: () => set({
          thumbsUpAndDownLegacy: {
            clicked: true,
            open: true,
            success: false,
            thumbsUp: false,
            thumbsDown: true,
          }
        }),
        setOpen_thumbsUpAndDownRevised: () => set({
          thumbsUpAndDownRevised: {
            clicked: true,
            open: true,
            success: false,
            thumbsUp: false,
            thumbsDown: true,
          }
        }),
        setCategoryLegacy_execute: (category) => set({ categoryLegacy: category }),
        setCategoryRevised_execute: (category) => set({ categoryRevised: category })
      }),

      {
        name: "predict-storage",

      },
    )
  ))

export const usePredictLimeText = () => {
  const { predictText, process, clear } = usePredictsStore((state) => ({
    predictText: state.predictLime_execute,
    process: state.predictLime_process,
    clear: state.predictLime_clear,
  }), shallow)
  useEffect(() => () => { clear() }, [clear])
  return useMemo(() => ({ predictText, process }), [predictText, process])
}

export const usePredictLegacyText = () => {
  const { clear, predictLegacyText, process, clean } = usePredictsStore((state) => ({
    predictLegacyText: state.predictLegacy_execute,
    process: state.predictLegacy_process,
    clear: state.predictLegacy_clear,
    clean: state.clean_predictLegacy
  }), shallow)
  useEffect(() => () => { clear() }, [clear])
  return useMemo(() => ({ predictLegacyText, process, clean }), [predictLegacyText, process, clean])
}

export const usePredictRevisedText = () => {
  const { clear, predictRevisedText, process, clean } = usePredictsStore((state) => ({
    predictRevisedText: state.predictRevised_execute,
    process: state.predictRevised_process,
    clear: state.predictRevised_clear,
    clean: state.clean_predictRevised

  }), shallow)
  useEffect(() => () => { clear() }, [clear])
  return useMemo(() => ({ predictRevisedText, process, clean }), [predictRevisedText, process, clean])
}

export const useSendFeedback = () => {
  const { clear, sendFeedback, process } = usePredictsStore((state) => ({
    sendFeedback: state.sendFeedback_execute,
    process: state.sendFeedback_process,
    clear: state.sendFeedback_clear,

  }), shallow)
  useEffect(() => () => { clear() }, [clear])
  return useMemo(() => ({ sendFeedback, process }), [sendFeedback, process])
}