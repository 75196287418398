import { yupResolver } from "@hookform/resolvers/yup";
import { Elevations } from "components/Atoms/Elevations/Elevations";
import Form from "components/Atoms/Form";
import { StyledButton } from "components/Atoms/StyledButton/StyledButton";
import { StyledTextField } from "components/Atoms/StyledTextField";
import React from "react";
import { useForm } from "react-hook-form";
import { withControllerInput } from "utils/hocs/withControllerInput";
import { confirmPasswordRecover } from "utils/validationSchemas/validationSchema";
import "./styles.scss";
export interface NewPasswordFormModel {
  password: string;
}

interface NewPasswordFormProps {
  onSubmit: (data: NewPasswordFormModel) => void;
}

const ControllerTextField = withControllerInput(StyledTextField);

export const NewPasswordForm = ({ onSubmit }: NewPasswordFormProps) => {
  const methods = useForm<NewPasswordFormModel>({
    mode: "onChange",
    resolver: yupResolver(confirmPasswordRecover),
  });
  const { control, formState } = methods;
  const { isValid } = formState;
  return (
    <div>
      <Elevations variant="rectangle-1" className="new-password-form-container">
        <Form
          methods={methods}
          onSubmit={onSubmit}
          className="new-password-form"
        >
          <ControllerTextField
            control={control}
            name={"password"}
            id="password"
            label="forms.login.password"
            password
            required
            fullWidth
          />
          <StyledButton
            buttonVariant="contained"
            title="button.continue"
            size="large"
            isSubmbit
            isDisabled={isValid ? false : true}
          />
        </Form>
      </Elevations>
    </div>
  );
};
