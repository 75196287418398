import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import "./style.scss";
import { getColor } from "theme/colors";

interface item {
  label: string;
  value: string | number;
}

export interface InputSelectProps {
  value?: string;
  onChange?: () => void;
  disabled?: boolean;
  errorMessage?: string;
  label: string;
  name: string;
  valueOptions: item[];
  id: string;
}

export const InputSelect = ({
  value,
  onChange,
  disabled,
  errorMessage,
  label,
  name,
  valueOptions,
  id,
}: InputSelectProps) => {
  return (
    <div>
      <FormControl error={Boolean(errorMessage)}>
        <InputLabel
          sx={
            disabled
              ? { color: `${getColor("grey20")} !important` }
              : errorMessage
              ? { color: `${getColor("red")} !important` }
              : {}
          }
          id={`${name}-label`}
          className="labelSelect"
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${name}-label`}
          id={id}
          value={value}
          onChange={onChange}
          label={name}
          disabled={disabled}
          name={name}
        >
          {valueOptions.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormHelperText sx={{ mb: 2 }}>{errorMessage}</FormHelperText>
    </div>
  );
};
