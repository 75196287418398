import Enflux from "services";

export interface LegacyCategories {
  [key: string]: string | null;
  knowledge: string | null;
  comprehension: string | null;
  application: string | null | null;
  analysis: string | null | null;
  synthesis: string | null;
  evaluation: string | null;
}

export interface RevisedCategories {
  [key: string]: string | null;
  remember: string | null;
  understand: string | null;
  apply: string | null;
  analyze: string | null;
  evaluate: string | null;
  create: string | null;
}

export const legacyMapping = async (categories: LegacyCategories) => {
  const request = await Enflux.post("/mappings/legacy", {
    analysis: categories.analysis,
    application: categories.application,
    comprehension: categories.comprehension,
    evaluation: categories.evaluation,
    knowledge: categories.knowledge,
    synthesis: categories.synthesis,
  });
  return request.data;
};

export const revisedMapping = async (categories: RevisedCategories) => {
  const request = await Enflux.post("/mappings/revised", {
    remember: categories.remember,
    understand: categories.understand,
    apply: categories.apply,
    analyze: categories.analyze,
    evaluate: categories.evaluate,
    create: categories.create,
  });
  return request.data;
};

export const getCategories = async (
  mappingType: string | null
): Promise<any> => {
  return await Enflux.get(`/mappings/${mappingType}`);
};
