import { StyledTextField } from "components/Atoms/StyledTextField"
import { withControllerInput } from "utils/hocs/withControllerInput"
import { useForm } from "react-hook-form"
import Form from "components/Atoms/Form"
import { yupResolver } from "@hookform/resolvers/yup"
import { registerSchema } from "utils/validationSchemas/validationSchema"
import "./style.scss"
import { StyledButton } from "components/Atoms/StyledButton/StyledButton"
import { StyledCheckBox } from "components/Atoms/StyledCheckBox/StyledCheckBox"
import { withControllerCheckBox } from "utils/hocs/withControllerCheckBox"
import { UserSignUp } from "services/user.service"
import { useIntl } from "react-intl"
import { PROCESS_STATE, useUserSignup } from "store/UserStore"

export type RegisterFormModel = UserSignUp

interface RegisterFormProps {
  onClick: (data: RegisterFormModel) => void
}

const ControllerTextInput = withControllerInput(StyledTextField)
const ControllerCheckbox = withControllerCheckBox(StyledCheckBox)

export const RegisterForm = ({ onClick }: RegisterFormProps) => {
  const { process } = useUserSignup()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const referral = urlParams.get("referral")
  const intl = useIntl()

  const methods = useForm<RegisterFormModel>({
    mode: "onChange",
    resolver: yupResolver(registerSchema),
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      phone_number: "",
      title: "",
      university: "",
      referral_id: referral ?? "",
    },
  })
  const { control, handleSubmit, formState } = methods
  const { isValid, errors } = formState
  const errorEmailMessage = () => {
    switch (process.data) {
      case 400:
        return "register.error.email.incorrect"
      case 409:
        return "register.error.email.duplicated"
      default:
        return errors.email?.message && errors.email?.message
    }
  }
  return (
    <Form
      methods={methods}
      onSubmit={handleSubmit((data) => {
        onClick(data)
      })}>
      <div className="register-form-container">
        <ControllerTextInput
          label="firstName.label"
          name="first_name"
          id="first_name"
          required
          control={control}
          className={"controllerMargin"}
          errorMessage={errors.first_name?.message}
          fullWidth
        />
        <ControllerTextInput
          label="lastName.label"
          name="last_name"
          id="last_name"
          required
          control={control}
          className={"controllerMargin"}
          errorMessage={errors.last_name?.message}
          fullWidth
        />
        <ControllerTextInput
          label="workEmail.label"
          name="email"
          id="email"
          required
          control={control}
          className={"controllerMargin"}
          fullWidth
          errorMessage={errorEmailMessage()}
        />
        <ControllerTextInput
          label="phone.label"
          name="phone_number"
          id="phone"
          required
          control={control}
          className={"controllerMargin"}
          maxLength={10}
          errorMessage={errors.phone_number?.message}
          fullWidth
        />

        <ControllerTextInput
          label="jobTitle.label"
          name="title"
          id="title"
          required
          control={control}
          className={"controllerMargin"}
          errorMessage={errors.title?.message}
          fullWidth
        />

        <ControllerTextInput
          label="institution.label"
          name="university"
          id="university"
          required
          control={control}
          className={"controllerMargin"}
          errorMessage={errors.university?.message}
          fullWidth
        />

        <ControllerTextInput
          label="forms.login.password"
          name="password"
          id="password"
          required
          password
          control={control}
          className={"controllerMargin"}
          errorMessage={errors.password?.message}
          fullWidth
        />

        <div className="terms-checkbox-container">
          <div className="checkbox-item">
            <ControllerCheckbox
              name={"terms"}
              control={control}
              required
              defaultChecked={false}
            />
          </div>
          <div className="first-line-checkbox-text">
            <div>
              <p>{intl.formatMessage({ id: "terms.agreement.first" })}&nbsp;</p>
              <a
                href={"https://enflux.com/legal/"}
                target="_blank"
                rel="noreferrer">
                <p className="primary100 text-left underline">
                  {intl.formatMessage({ id: "terms.agreement.second" })}
                </p>
              </a>
              <p>
                &nbsp;{intl.formatMessage({ id: "terms.agreement.third" })}
                &nbsp;
              </p>
              <a
                href={"https://enflux.com/privacy/"}
                target="_blank"
                rel="noreferrer">
                <p className="primary100 text-left underline">
                  {intl.formatMessage({ id: "terms.agreement.fourth" })}
                </p>
              </a>
              <p>&nbsp;{intl.formatMessage({ id: "terms.agreement.fifth" })}</p>

              <p>{intl.formatMessage({ id: "terms.agreement.sixth" })}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="register-submit-button-container">
        <StyledButton
          size="large"
          buttonVariant="contained"
          isSubmbit
          title="register.label"
          isDisabled={!isValid}
          isLoading={process.state === PROCESS_STATE.EXECUTING}
        />
      </div>
    </Form>
  )
}
