export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function removeDuplicates(arr: any[]) {
  const seen: any = {};
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (!seen[item]) {
      seen[item] = true;
      result.push(item);
    }
  }
  return result;
}

export const noSpecialCharsString = (publicName: string): string => {
  if (publicName !== null && publicName !== undefined) {
    return publicName.replace(/[^a-zA-Z\s]/g, '').replace(/\d+$/, '');
  } else {
    return "";
  }
};

export const justNumbersString = (publicString: string): string => {
  if (publicString !== null && publicString !== undefined) {
    return publicString.replace(/[^0-9]+/g, "");
  } else {
    return "";
  }
};

export const justCharsAndNumbersString = (publicString: string): string => {
  if (publicString !== null && publicString !== undefined) {
    return publicString.replace(/[^a-zA-Z0-9 ]/g, "");
  } else {
    return "";
  }
};

export const justCharsAndNumbersStringWithSpaces = (
  publicString: string
): string => {
  if (publicString !== null && publicString !== undefined) {
    return publicString.replace(/[^a-zA-Z0-9 ]/g, "");
  } else {
    return "";
  }
};

export const justCharsAndNumbersStringWithHyphen = (
  publicString: string
): string => {
  if (publicString !== null && publicString !== undefined) {
    return publicString.replace(/[^a-zA-Z0-9-]/g, "");
  } else {
    return "";
  }
};
export const noRepeatSpaces = (publicString: string): string => {
  if (!publicString) {
  } else if (publicString.slice(0, 1) === " ") {
    return publicString.slice(1);
  } else if (
    publicString.length > 2 &&
    publicString.slice(-1) === " " &&
    publicString.slice(-2)[0] === " "
  ) {
    return publicString.slice(0, -1);
  }
  return publicString;
};

export const bytesToMb = (bytes?: number) => {
  return bytes ? bytes / 1000000 : null;
};
