import { REGEXP_VALIDATIONS } from "../../constants";
import * as Yup from "yup";
import { string, ref } from "yup";

const reusableTypes = {
  email: string()
    .required("register.error.email.required")
    .email("register.error.email.valid").test('no-trailing-space', 'register.error.firstname.trailingSpace', value => {
      if (value) {
        return !value.endsWith(' ');
      }
      return true;
    }),
  password: string()
    .required("register.error.password.required")
    .min(10, "register.error.password.min"),
  confirmPassword: string().oneOf(
    [ref("password")],
    "register.error.confirmpassword.unmatch"
  ),
};

export const registerUserResolver = Yup.object().shape({
  email: string()
    .required("register.error.email.required")
    .email("register.error.email.valid"),
  password: reusableTypes.password,
  confirmPassword: string().oneOf(
    [ref("password")],
    "register.error.confirmpassword.unmatch"
  ),
  checkbox: Yup.boolean()
    .required("register.error.checkbox.accept")
    .isTrue("register.error.checkbox.accept"),
});

export const emailRecoverPassword = Yup.object().shape({
  email: reusableTypes.email,
});

export const confirmPasswordRecover = Yup.object().shape({
  password: reusableTypes.password,
});

export const loginSchema = Yup.object().shape({
  email: reusableTypes.email,
  password: string()
    .required("register.error.password.required")
    .min(10, "register.error.password.min"),
});

export const registerSchema = Yup.object().shape({
  first_name: string().required("register.error.firstname.required")
    .test('no-trailing-space', 'register.error.firstname.trailingSpace', value => {
      if (value) {
        return !value.endsWith(' ');
      }
      return true;
    }),
  last_name: string().required("register.error.lastname.required")
    .test('no-trailing-space', 'register.error.firstname.trailingSpace', value => {
      if (value) {
        return !value.endsWith(' ');
      }
      return true;
    }),
  email: reusableTypes.email,
  phone_number: string()
    .required("register.error.phone.required")
    .matches(REGEXP_VALIDATIONS.PHONE, "register.error.phone.valid")
    .length(10, "register.error.phone.valid"),
  title: string().required("register.error.jobitle.required")
    .test('no-trailing-space', 'register.error.firstname.trailingSpace', value => {
      if (value) {
        return !value.endsWith(' ');
      }
      return true;
    }),
  university: string().required("register.error.institution.required").test('no-trailing-space', 'register.error.firstname.trailingSpace', value => {
    if (value) {
      return !value.endsWith(' ');
    }
    return true;
  }),
  password: reusableTypes.password,
  terms: Yup.boolean()
    .oneOf([true], "terms.agreement.error.required")
    .default(false),
});

export const questionText = Yup.object().shape({
  question: string().required().min(8, "error.classifier.question"),
});

export const batchFeedbackSchema = Yup.object().shape({
  category: string()
    .required("batch.feedback.category.error")
    .oneOf([
      "analysis",
      "application",
      "comprehension",
      "evaluation",
      "knowledge",
      "synthesis",
      "remember",
      "understand",
      "apply",
      "analyze",
      "evaluate",
      "create",
    ]),
  comment: string(),
});
