import { Controller } from "react-hook-form";
import React from "react";
import { CheckBoxProps } from "components/Atoms/StyledCheckBox/StyledCheckBox";

interface CheckBoxPropsHoc extends CheckBoxProps {
  name: string;
  control: any;
}

export const withControllerCheckBox = (
  WrappedComponent: (props: CheckBoxProps) => JSX.Element
) => {
  const WithControllerCheckBox = (props: CheckBoxPropsHoc) => {
    const { name, control } = props;
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <WrappedComponent
              {...props}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
            />
          );
        }}
      />
    );
  };
  return WithControllerCheckBox;
};
