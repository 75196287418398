import axios from "axios";

export const DownloadFile = async (
  url: string,
  accessToken: string,
  fileName: string
) => {
  try {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    });

    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    console.log(error);
  }
};
