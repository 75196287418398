import React from "react"
import { Drawer } from "@mui/material"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import { X } from "phosphor-react"
import "./styles.scss"
import Img from "components/Atoms/Img"
import { getColor } from "theme/colors"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../../constants"
import { useIntl } from "react-intl"
export interface MenuListI {
  name: string
  link: string
}

interface DrawerComponentProps {
  open: boolean
  onClose: () => void
  menuList: MenuListI[]
}

export const DrawerComponent = ({
  onClose,
  open,
  menuList,
}: DrawerComponentProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <>
      <Drawer open={open} onClose={onClose} anchor={"left"}>
        <div className="header-drawer-container">
          <Img
            onClick={() => navigate(ROUTES.HOME)}
            src={"/images/main_logo.png"}
            alt="logo"
            className="main-logo-img"
            width="183"
            height="40"
          />
          <X onClick={onClose} size={32} color={getColor("primary100")} />
        </div>
        {menuList.map((item, key) => {
          return (
            <StyledText
              key={key}
              className={"item-list-drawer-text"}
              link
              To={item.link}
              color="natural-black"
              align="left"
              variant="label-medium">
              {intl.formatMessage({ id: item.name })}
            </StyledText>
          )
        })}
      </Drawer>
    </>
  )
}
