import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "components/Atoms/Form";
import React from "react";
import { withControllerInput } from "utils/hocs/withControllerInput";
import { StyledTextField } from "components/Atoms/StyledTextField";
import "./styles.scss";
import { StyledButton } from "components/Atoms/StyledButton/StyledButton";
import { loginSchema } from "utils/validationSchemas/validationSchema";
import { ROUTES } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import { useUserLogin } from "store/UserStore";
export interface LoginFormModel {
  email: string;
  password: string;
}

interface LoginFormProps {
  onClick: (data: LoginFormModel) => void;
  isLoading: boolean;
}

const ControllerTextInput = withControllerInput(StyledTextField);

export const LoginForm = ({ onClick, isLoading }: LoginFormProps) => {
  const navigate = useNavigate();
  const { process } = useUserLogin();
  const methods = useForm<LoginFormModel>({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
    defaultValues: { email: "", password: "" },
  });
  const { control, handleSubmit, formState } = methods;
  const { isValid } = formState;

  return (
    <div className="logIn-form-container">
      <Form
        className="logIn-form"
        methods={methods}
        onSubmit={handleSubmit((data) => {
          onClick(data);
        })}
      >
        <ControllerTextInput
          control={control}
          name="email"
          id="email-text-input"
          label="forms.login.email"
          fullWidth
          highlightError={process.data === 401}
        />
        <div className="password-container">
          <ControllerTextInput
            password
            control={control}
            name="password"
            id="password"
            label="forms.login.password"
            fullWidth
            highlightError={process.data === 401}
          />
          <div className="forgot-password-button">
            <StyledButton
              size="large"
              buttonVariant="text"
              title={"screen.login.forgot.password"}
              onClick={() => navigate(ROUTES.RECOVERPASSWORD)}
            />
          </div>
        </div>
        <StyledButton
          size="large"
          buttonVariant="contained"
          isSubmbit
          title="forms.login.button"
          isDisabled={isValid ? false : true}
          isLoading={isLoading}
        />
      </Form>
    </div>
  );
};
