import Enflux from "services";
import { LegacyResponse, RevisedResponse } from "utils/helpers/respones.helper";
import EnfluxFormData from "./formdata";

//single question

export const predictLime = async (question: string) => {
  return await Enflux.post("predict/lime", { input_text: question });
};

export const predictLegacyQuestion = async (
  question: string
): Promise<LegacyResponse> => {
  return await Enflux.post("predict/legacy", { input_text: question });
};

export const predictRevisedQuestion = async (
  question: string
): Promise<RevisedResponse> => {
  return await Enflux.post("predict/revised", { input_text: question });
};

// batch

export const predictLegacyBatch = async (
  input_file: FormData
): Promise<any> => {
  return await EnfluxFormData.post("predict/batch/legacy", input_file);
};

export const predictRevisedBatch = async (
  input_file: FormData
): Promise<any> => {
  return await EnfluxFormData.post("predict/batch/revised", input_file);
};

export const getBatchStatus = async (batch_id: string): Promise<any> => {
  return await Enflux.get(`predict/batch/status/${batch_id}`);
};

export const getPredictionsBatch = async (batch_id: string): Promise<any> => {
  return await Enflux.get(`predict/batch/predictions/${batch_id}`);
};

//feedback

export const sendFeedback = async (
  is_positive: boolean,
  correct_classification: string,
  comment: string,
  prediction_ruuid: string
): Promise<any> => {
  return await Enflux.post(`predict/feedback/${prediction_ruuid}`, {
    is_positive,
    correct_classification,
    comment,
  });
};

//get ALL predictions

export const getAllPredictions = async (): Promise<any> => {
  return await Enflux.get("predict/predictions");
};
