import React, { useCallback, useEffect, useState } from "react"
import "./styles.scss"
import { InputQuestionClassifierInput } from "../InputQuestionClassifierInput/InputQuestionClassifierInput"
import {
  usePredictLegacyText,
  usePredictLimeText,
  usePredictRevisedText,
  usePredictsStore,
} from "store/PredictTextStore"
import { Iframe } from "components/Atoms/Iframe/Iframe"
import {
  useTierReferralMe,
  useTierReferralsStore,
} from "store/TierReferralsStore"
import { PROCESS_STATE } from "store/UserStore"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import { CaretDown, CaretUp } from "phosphor-react"
import { getColor } from "theme/colors"
import { useCategoriesStore } from "store/CategoriesStore"
import { PercentageCardsComponent } from "../PercentageCardsComponent/PercentageCardsComponent"
import { SingleFeedBack } from "../SingleFeedBack/SingleFeedBack"

interface OneByOneClassifierComponentProps {
  taxonomy: "original" | "revised"
}

export const OneByOneClassifierComponent = ({
  taxonomy,
}: OneByOneClassifierComponentProps) => {
  const [percentageCardArrayLegacy, setPercentageCardArrayLegacy] =
    useState<any>(null)
  const [percentageCardArrayRevised, setPercentageCardArrayRevised] =
    useState<any>(null)
  const [isLegacyShown, setIsLegacyShown] = useState(false)
  const [isRevisedShow, setIsShownRevised] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [isFirstTimeRender, setIsFirstTimeRender] = useState(true)
  const { predictText, process: limeProcess } = usePredictLimeText()
  const {
    predictLegacyText,
    process: legacyProcess,
    clean: legacyClean,
  } = usePredictLegacyText()
  const {
    predictRevisedText,
    process: revisedProcess,
    clean: revisedClean,
  } = usePredictRevisedText()
  const { getTierDataUser } = useTierReferralMe()
  const { predictionsLeft } = useTierReferralsStore().tierDataUser
  const { htmlLegacy, htmlRevised, legacy, revised } = usePredictsStore()
  const { legacyCategories, revisedCategories } = useCategoriesStore()
  const categories =
    taxonomy === "original" ? legacyCategories : revisedCategories
  const prediction = taxonomy === "original" ? legacy![0] : revised![0]

  const handleSubmit = async (question: string) => {
    predictText(question, taxonomy, () => null)
    setIsFirstTimeRender(false)
    if (taxonomy === "original") {
      predictLegacyText(question, getTierDataUser)
      setIsLegacyShown(true)
      createArrayToMapForPercentageCards()
    } else {
      predictRevisedText(question, getTierDataUser)
      setIsShownRevised(true)
      createArrayToMapForPercentageCards()
    }
  }

  const onClickDeleteText = () => {
    if (taxonomy === "original") {
      legacyClean()
      setPercentageCardArrayLegacy(null)
      setIsLegacyShown(false)
    } else {
      revisedClean()
      setPercentageCardArrayRevised(null)
      setIsShownRevised(false)
    }
  }
  const isLoading =
    limeProcess.state === PROCESS_STATE.EXECUTING ||
    legacyProcess.state === PROCESS_STATE.EXECUTING ||
    revisedProcess.state === PROCESS_STATE.EXECUTING

  const sumCategoriesPercentage = useCallback((categoryMapped: string) => {
    let sum = 0
    for (let index = 0; index < Object.keys(prediction).length; index++) {
      if (
        categories[Object.entries(prediction)[index][0]] !== null &&
        categories[Object.entries(prediction)[index][0]] === categoryMapped
      ) {
        sum += Object.entries(prediction)[index][1]
      }
    }
    return sum
  },[categories, prediction])

  const createArrayToMapForPercentageCards = useCallback(async () => {
    let sum = 0
    const newArray = []

    for (let index = 0; index < Object.keys(prediction).length; index++) {
      if (
        categories[Object.entries(prediction)[index][0]] !== null &&
        Object.entries(prediction)[index][0] ===
          Object.entries(categories)[index][0]
      ) {
        newArray.push([
          {
            name: Object.entries(prediction)[index][0],
            customName: Object.entries(categories)[index][1],
            total: sumCategoriesPercentage(
              Object.entries(categories)[index][1]!
            ),
            singleValue: Object.entries(prediction)[index][1],
          },
        ])
      } else {
        Object.entries(prediction).forEach(([category]) => {
          if (category === Object.entries(prediction)[index][0].toLowerCase()) {
            newArray.push([
              {
                name: Object.entries(prediction)[index][0],
                customName:
                  Object.entries(categories)[index][1] === null &&
                  Object.entries(categories)[index][0] === category &&
                  Object.entries(categories)[index][0] ===
                    Object.entries(prediction)[index][0]
                    ? Object.entries(categories)[index][0]
                    : null,
                total: sum,
                singleValue: Object.entries(prediction)[index][1],
              },
            ])
          }
        })
      }
    }

    if (taxonomy === "original") {
      setPercentageCardArrayLegacy(newArray)
    } else {
      setPercentageCardArrayRevised(newArray)
    }
  }, [categories, prediction, taxonomy, sumCategoriesPercentage])

  useEffect(() => {
    createArrayToMapForPercentageCards()
  }, [createArrayToMapForPercentageCards])

  return (
    <div className="one-by-one-classifier-container">
      <InputQuestionClassifierInput
        onClickDeleteText={onClickDeleteText}
        handleSubmitQuestion={(data) => handleSubmit(data.question)}
        isDisabled={predictionsLeft === 0 || isLoading}
        isLoading={isLoading}
      />

      <PercentageCardsComponent
        showLegacyCards={isLegacyShown}
        showRevisedCards={isRevisedShow}
        taxonomy={taxonomy}
        legacyData={percentageCardArrayLegacy}
        revisedData={percentageCardArrayRevised}
      />

      {htmlLegacy !== null && taxonomy === "original" && !isFirstTimeRender && (
        <div
          className="showMoreData"
          onClick={() => {
            setShowMore(!showMore)
          }}>
          <StyledText
            variant="body-medium"
            color="primary100"
            className={"hoverElement"}
            underline>
            {showMore ? "Show Less" : "Show More"}
          </StyledText>
          {showMore ? (
            <CaretUp
              size={18}
              color={getColor("primary100")}
              className={"hoverElement"}
              style={{ marginLeft: "0.3rem" }}
            />
          ) : (
            <CaretDown
              size={18}
              color={getColor("primary100")}
              className={"hoverElement"}
              style={{ marginLeft: "0.3rem" }}
            />
          )}
        </div>
      )}

      {htmlRevised !== null && taxonomy === "revised" && !isFirstTimeRender && (
        <div
          className="showMoreData"
          onClick={() => {
            setShowMore(!showMore)
          }}>
          <StyledText
            variant="body-medium"
            color="primary100"
            className={"hoverElement"}
            underline>
            {showMore ? "Show Less" : "Show More"}
          </StyledText>
          {showMore ? (
            <CaretUp
              size={18}
              color={getColor("primary100")}
              className={"hoverElement"}
              style={{ marginLeft: "0.3rem" }}
            />
          ) : (
            <CaretDown
              size={18}
              color={getColor("primary100")}
              className={"hoverElement"}
              style={{ marginLeft: "0.3rem" }}
            />
          )}
        </div>
      )}

      {showMore && taxonomy === "original" && htmlLegacy && (
        <Iframe content={htmlLegacy} />
      )}

      {showMore && taxonomy === "revised" && htmlRevised && (
        <Iframe content={htmlRevised} />
      )}
      {isLegacyShown && htmlLegacy && taxonomy === "original" && (
        <SingleFeedBack taxonomy={taxonomy} />
      )}
      {isRevisedShow && htmlRevised && taxonomy === "revised" && (
        <SingleFeedBack taxonomy={taxonomy} />
      )}
    </div>
  )
}
