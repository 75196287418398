import React from "react"
import { RadioGroup, Radio } from "@mui/material"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import "./styles.scss"
import { useIntl } from "react-intl"
interface StyledRadioButtonProps {
  value: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  radioData: RadioItem[]
  isDisabled?: boolean
}

export type RadioItem = {
  value: string
  label: string
}

export const StyledRadioButton = ({
  onChange,
  value,
  radioData,
  isDisabled,
}: StyledRadioButtonProps) => {
  const intl = useIntl()
  return (
    <div className="radio-group-contaier">
      <RadioGroup value={value} onChange={onChange}>
        {radioData.map((item, key) => {
          return (
            <div
              className={`radio-contaier ${isDisabled ? "disabled" : ""}`}
              key={key}
              data-testid={`radio-${key}`}>
              <Radio disableRipple value={item.value} size={"small"} disabled={isDisabled} />
              <StyledText
                align="left"
                variant="label-medium"
                color="natural-black">
                {intl.formatMessage({ id: item.label })}
              </StyledText>
            </div>
          )
        })}
      </RadioGroup>
    </div>
  )
}
