import Enflux from "services";

interface NewPasswordI {
  email: string,
  new_password: string,
  update_token: string
}

export const sendEmailToRecoverPassword = async (email: string) => {
  return await Enflux.get(`/users/forgot_password/${email}`)
}

export const setNewPassword = async (newPassword: NewPasswordI) => {
  return await Enflux.post(`/users/password`, newPassword)
}