import { StyledText } from "components/Atoms/StyledText/StyledText";
import "./style.scss";
import { InputSelect } from "components/Atoms/InputSelect";
import { withControllerInput } from "utils/hocs/withControllerInput";
import { StyledTextField } from "components/Atoms/StyledTextField";
import { withControllerSelect } from "utils/hocs/withControllerSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { batchFeedbackSchema } from "utils/validationSchemas/validationSchema";
import { useIntl } from "react-intl";
import Form from "components/Atoms/Form";
import { StyledButton } from "components/Atoms/StyledButton/StyledButton";
import { taxonomyValueType } from "../TaxonomyVersion/TaxonomyVersion";

const ControllerTextInput = withControllerInput(StyledTextField);
const ControllerSelect = withControllerSelect(InputSelect);

interface BatchFeedbackProps {
  currentCategory: string;
  taxonomy: taxonomyValueType;
  onCancel: () => void;
  onSubmit: (e: any) => void;
}

export const BatchFeedback = ({
  currentCategory,
  taxonomy,
  onCancel,
  onSubmit,
}: BatchFeedbackProps) => {
  const intl = useIntl();
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(batchFeedbackSchema),
    defaultValues: { category: "", comment: "" },
  });
  const { control, handleSubmit, formState } = methods;
  const { isValid } = formState;
  const options =
    taxonomy === "original"
      ? [
          { value: "knowledge", label: "Knowledge" },
          { value: "comprehension", label: "Comprehension" },
          { value: "application", label: "Application" },
          { value: "analysis", label: "Analysis" },
          { value: "synthesis", label: "Synthesis" },
          { value: "evaluation", label: "Evaluation" },
        ].filter((item) => item.value !== currentCategory)
      : [
          { value: "remember", label: "Remember" },
          { value: "understand", label: "Understand" },
          { value: "apply", label: "Apply" },
          { value: "analyze", label: "Analyze" },
          { value: "evaluate", label: "Evaluate" },
          { value: "create", label: "Create" },
        ].filter((item) => item.value !== currentCategory);

  return (
    <div className="batchFeedbackContainer">
      <Form
        methods={methods}
        onSubmit={handleSubmit((e) => {
          onSubmit(e);
        })}
      >
        <div className="batchFeedbackField">
          <StyledText>
            {intl.formatMessage({ id: "batch.feedback.first.question" })}
          </StyledText>
          <ControllerSelect
            name="category"
            id="category"
            control={control}
            label={intl.formatMessage({
              id: "batch.feedback.choose.category.field",
            })}
            valueOptions={options}
          />
        </div>
        <div className="batchFeedbackField">
          <StyledText>
            {intl.formatMessage({ id: "batch.feedback.second.question" })}
          </StyledText>
          <div className="input-comment-container">
            <ControllerTextInput
              label="batch.feedback.optional.field"
              name="comment"
              id="comment"
              control={control}
              fullWidth
              // errorMessage="testing"
            />
          </div>
        </div>
        <div className="feedbackBatchButtons">
          <StyledButton
            size="medium"
            buttonVariant="text"
            title="cancel.label"
            onClick={onCancel}
          />
          <StyledButton
            size="medium"
            buttonVariant="text"
            isSubmbit
            title="send.label"
            isDisabled={!isValid}
          />
        </div>
      </Form>
    </div>
  );
};
