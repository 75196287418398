import React, { MouseEventHandler, useEffect, useState } from "react"
import "./styles.scss"
import { Tooltip } from "@mui/material"

interface StyledChipsProps {
  labelId: string
  isDisabled?: boolean
  rightIcon?: React.ReactElement
  leftIcon?: React.ReactElement
  className?: string
  customRef?: any
  toolTipText?: string | null
  onClick?: MouseEventHandler<HTMLDivElement>
  onChange?: () => void
}
export const StyledChips = ({
  labelId,
  isDisabled,
  rightIcon,
  leftIcon,
  className,
  customRef,
  toolTipText,
  onClick,
  onChange,
  ...rest
}: StyledChipsProps) => {
  const [isBeingDragged, setIsBeingDragged] = useState(false)
  useEffect(() => {
    window.addEventListener("dragstart", () => {
      setIsBeingDragged(true)
    })

    window.addEventListener("dragend", () => {
      setIsBeingDragged(false)
    })

    return () => {
      window.removeEventListener("dragstart", () => null)
      window.removeEventListener("dragend", () => null)
    }
  }, [])

  return (
    <>
      {toolTipText ? (
        <Tooltip title={toolTipText} placement="top">
          <div
            className={`chip-component ${className ? className : ""} ${
              (leftIcon && rightIcon) || (rightIcon && !leftIcon)
                ? "both-icons"
                : leftIcon && !rightIcon
                ? "left-icon"
                : ""
            } ${isDisabled ? "disabled" : ""} ${
              isBeingDragged ? "dragged" : ""
            } ${onClick ? "on-click" : ""}`}
            tabIndex={0}
            draggable={true}
            ref={customRef}
            {...rest}>
            {leftIcon && rightIcon ? (
              <>
                <div data-testid="leftIcon">{leftIcon}</div>
                {labelId}
                <div data-testid="rightIcon">{rightIcon}</div>
              </>
            ) : leftIcon && !rightIcon ? (
              <div style={{ display: "flex" }}>
                <div data-testid="leftIcon" style={{ marginRight: "0.5rem" }}>
                  {leftIcon}
                </div>
                {labelId}
              </div>
            ) : !leftIcon && rightIcon ? (
              <>
                {labelId}
                <div data-testid="rightIcon">{rightIcon}</div>
              </>
            ) : (
              !leftIcon && !rightIcon && <> {labelId}</>
            )}
          </div>
        </Tooltip>
      ) : (
        <div
          onClick={onClick}
          onChange={onChange}
          className={`chip-component ${className ? className : ""} ${
            (leftIcon && rightIcon) || (rightIcon && !leftIcon)
              ? "both-icons"
              : leftIcon && !rightIcon
              ? "left-icon"
              : ""
          } ${isDisabled ? "disabled" : ""} ${
            isBeingDragged ? "dragged" : ""
          } ${onClick ? "on-click" : ""}`}
          tabIndex={0}
          draggable={true}
          ref={customRef}
          {...rest}>
          {leftIcon && rightIcon ? (
            <>
              <div data-testid="leftIcon">{leftIcon}</div>
              {labelId}
              <div data-testid="rightIcon">{rightIcon}</div>
            </>
          ) : leftIcon && !rightIcon ? (
            <div style={{ display: "flex" }}>
              <div data-testid="leftIcon" style={{ marginRight: "0.5rem" }}>
                {leftIcon}
              </div>
              {labelId}
            </div>
          ) : !leftIcon && rightIcon ? (
            <>
              {labelId}
              <div data-testid="rightIcon">{rightIcon}</div>
            </>
          ) : (
            !leftIcon && !rightIcon && <> {labelId}</>
          )}
        </div>
      )}
    </>
  )
}
