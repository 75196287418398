interface ImgProps {
  src: string;
  alt?: string;
  className?: string;
  height?: string;
  width?: string;
  onClick?: () => void
}
export default function Img(props: ImgProps) {
  const { className, alt, src, onClick, ...rest } = props;
  return <img onClick={onClick} className={className} alt={alt || "Image"} src={src} {...rest} />;
}
