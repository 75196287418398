import { LinearProgress, Menu, MenuItem } from "@mui/material";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { API_HOST, ROUTES } from "../../../constants";
import { CopySimple, FileArrowDown, SignOut } from "phosphor-react";
import { useCallback, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import "./style.scss";
import { getColor } from "theme/colors";
import { DownloadFile } from "services/dowloadServices";
import { useUserStore } from "store/UserStore";

export interface MenuInformationI {
  firstName: string;
  referralCode: string;
  tierLevel: string;
  isUnlimited: boolean;
  predictionsLeft: number;
}

interface UserMenuProps {
  open: boolean;
  onClose: (e: any) => void;
  onLogout: () => void;
  data: MenuInformationI;
  id: string;
  anchorEl: any;
  predictionsQuota: number;
  tierLevel: string;
  isUnlimited: boolean;
  canDownload: boolean;
}

export const UserMenu = ({
  open,
  id,
  onClose,
  onLogout,
  data,
  anchorEl,
  predictionsQuota,
  tierLevel,
  isUnlimited,
  canDownload,
}: UserMenuProps) => {
  const intl = useIntl();
  const [copy, setCopy] = useState(false);
  const { access_token } = useUserStore.getState().session;
  const normalizeProgress = useCallback(
    (predictionsLeft: number) => {
      const result = (predictionsLeft * 100) / predictionsQuota;
      return result;
    },
    [predictionsQuota]
  );
  const copyToClipboard = () => {
    setCopy(true);
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_WEBHOST}/signup?referral=${data.referralCode}`
    );
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <Menu
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={(e) => onClose(e)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem className="tierItem" onClick={(e) => onClose(e)}>
        <div className="tierLevel">
          <StyledText
            variant="label-medium"
            align="center"
            color="natural-white"
            className={"tierText"}
          >
            {data.tierLevel}
          </StyledText>
        </div>
        <StyledText
          variant="label-medium"
          link
          className={"linkTiers"}
          To={ROUTES.TIERSANDREFERRALS}
        >
          {intl.formatMessage({ id: "seeAllTiers.userMenu" })}
        </StyledText>
      </MenuItem>
      <MenuItem disableTouchRipple className="nonHover">
        <div className="classificationsItem">
          <StyledText variant="label-medium" align="right">
            {!isUnlimited ? (
              <FormattedMessage
                id="youHavePredictionsLeft.text"
                values={{ predictionsLeft: data.predictionsLeft }}
              />
            ) : (
              <FormattedMessage id="youHaveUnlimitedPredictions.text" />
            )}
          </StyledText>
          <LinearProgress
            value={
              tierLevel.includes("Essentials")
                ? normalizeProgress(data.predictionsLeft)
                : 100
            }
            variant="determinate"
            color={"secondary"}
          />
        </div>
      </MenuItem>
      <MenuItem onClick={copyToClipboard}>
        {copy ? (
          <div className="copyItem">
            <StyledText className={"ml1"}>
              {intl.formatMessage({ id: "copied.userMenu" })}
            </StyledText>
          </div>
        ) : (
          <div className="copyItem">
            <CopySimple size={20} />
            <StyledText className={"ml1"}>
              {intl.formatMessage({ id: "referralCode.userMenu" })}
            </StyledText>
          </div>
        )}
      </MenuItem>
      {canDownload && (
        <MenuItem>
          <div
            onClick={() => {
              DownloadFile(
                `${API_HOST}/predict/predictions/file`,
                access_token!,
                "classification_history.xlsx"
              );
            }}
            className="downloadItem"
          >
            <FileArrowDown size={20} color={getColor("natural-black")} />
            <StyledText className={"ml1"}>
              {intl.formatMessage({ id: "download.history" })}
            </StyledText>
          </div>
        </MenuItem>
      )}
      <MenuItem onClick={onLogout} className="copyItem">
        <StyledText>{intl.formatMessage({ id: "LogOut.userMenu" })}</StyledText>
        <SignOut size={20} className="ml1" />
      </MenuItem>
    </Menu>
  );
};
