import { StyledButton } from "components/Atoms/StyledButton/StyledButton"
import { StyledModal } from "components/Atoms/StyledModal"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import { useModalStore } from "store/ModalStore"
import React, { useEffect } from "react"
import "./styles.scss"
import { useIntl } from "react-intl"
import { ArrowsClockwise } from "phosphor-react"

export const GenericErrorModal = () => {
  const intl = useIntl()
  const {
    valueGenericErrorModal,
    errorCodeGenericErrorModal,
    messageErrorGenericErrorModal,
    setCloseGenericErrorModal,
    cleanGenericModal,
  } = useModalStore()

  const closeModalAndReloadPage = () => {
    setCloseGenericErrorModal(false)
    window.location.reload()
  }
  useEffect(() => () => cleanGenericModal(), [cleanGenericModal])
  return (
    <div>
      <StyledModal
        onClose={() => {
          setCloseGenericErrorModal(false)
        }}
        open={valueGenericErrorModal}
        title={intl.formatMessage({ id: "generic.error.modal.title" })}
        width="315px">
        <div className="session-expired-description">
          <StyledText align="center">
            {messageErrorGenericErrorModal
              ? intl.formatMessage({
                  id: messageErrorGenericErrorModal,
                })
              : ""}
          </StyledText>
          <StyledButton
            size="large"
            buttonVariant="contained"
            title={
              errorCodeGenericErrorModal !== "500"
                ? "modal.session.expired.ok"
                : "modal.generic.error.try.again"
            }
            onClick={() => {
              errorCodeGenericErrorModal !== "500"
                ? setCloseGenericErrorModal(false)
                : closeModalAndReloadPage()
            }}
            withRightIcon={
              errorCodeGenericErrorModal !== "500" ? false : <ArrowsClockwise />
            }
          />
        </div>
      </StyledModal>
    </div>
  )
}
