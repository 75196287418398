import { ThumbsDown, ThumbsUp } from "phosphor-react"
import React from "react"
import "./styles.scss"
import { getColor } from "theme/colors"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import { useIntl } from "react-intl"
import { SendFeedBackForm } from "../Forms/SendFeedBackForm/SendFeedBackForm"
import { usePredictsStore, useSendFeedback } from "store/PredictTextStore"
import { Tooltip } from "@mui/material"
interface SingleFeedBackProps {
  taxonomy: "original" | "revised"
}

export const SingleFeedBack = ({ taxonomy }: SingleFeedBackProps) => {
  const intl = useIntl()
  const {
    prediction_ruuid,
    setTrue_thumbsUpAndDownLegacy,
    setFalse_thumbsUpAndDownLegacy,
    setFalse_thumbsUpAndDownRevised,
    setTrue_thumbsUpAndDownRevised,
    setOpen_thumbsUpAndDownLegacy,
    setOpen_thumbsUpAndDownRevised,
    thumbsUpAndDownLegacy,
    thumbsUpAndDownRevised,
  } = usePredictsStore()
  const { sendFeedback } = useSendFeedback()
  const handleSubmitSuccess = (data: any, category: string) => {
    taxonomy === "original"
      ? setFalse_thumbsUpAndDownLegacy()
      : setFalse_thumbsUpAndDownRevised()

    sendFeedback(false, category, data.feedbackText ?? "", prediction_ruuid)
  }

  const thumbs =
    taxonomy === "original" ? thumbsUpAndDownLegacy : thumbsUpAndDownRevised
  return (
    <div className="single-feedback-container">
      <div className="feedback-container">
        <div className="thumbs-container">
          <img
            src="/icons/thumbs.svg"
            alt="thumbs up and down"
            width={20}
            height={20}
          />
        </div>
        <Tooltip
            placement="top"
            title={intl.formatMessage({id: "feedback.disclaimer"})}>
            <div>
              <StyledText variant="body-large">
                {intl.formatMessage({id: "feedback.title"})}
              </StyledText>
            </div>
        </Tooltip>
        <div className="thumbsContainer">
          <div
            className={
              thumbs.clicked &&
              thumbs.success &&
              thumbs.thumbsUp === true &&
              thumbs.open === false
                ? "thumbContainer thumbContainerClicked "
                : thumbs.thumbsUp === false &&
                  thumbs.clicked &&
                  thumbs.open &&
                  thumbs.thumbsDown === true
                ? "thumbContainer thumbContainerDisabled thumbBorder "
                : "thumbContainer "
            }>
            <ThumbsUp
              size={23}
              color={getColor("green")}
              weight="fill"
              onClick={() => {
                if (thumbs.success === false) {
                  taxonomy === "original"
                    ? setTrue_thumbsUpAndDownLegacy()
                    : setTrue_thumbsUpAndDownRevised()
                  sendFeedback(true, "", "", prediction_ruuid)
                }
              }}
            />
          </div>
          <div
            className={
              thumbs.thumbsUp === false &&
              thumbs.open &&
              thumbs.clicked &&
              thumbs.thumbsDown
                ? "thumbContainer  thumbContainerClicked"
                : thumbs.thumbsUp === true &&
                  thumbs.success === true &&
                  thumbs.clicked &&
                  thumbs.open === false
                ? "thumbContainer thumbContainerDisabled"
                : "thumbContainer hoverElement"
            }>
            <ThumbsDown
              size={23}
              color={getColor("red")}
              weight="fill"
              onClick={() => {
                if (thumbs.success === false)
                  taxonomy === "original"
                    ? setOpen_thumbsUpAndDownLegacy()
                    : setOpen_thumbsUpAndDownRevised()
              }}
            />
          </div>
        </div>
        {thumbs.success === true ? (
          <div className="thankYouContainer">
            <StyledText>
              {intl.formatMessage({ id: "thankYou.label" })}
            </StyledText>
          </div>
        ) : null}
      </div>
      {thumbs.thumbsUp === false ? (
        <>
          <StyledText variant="body-medium">
          {intl.formatMessage({id: "feedback.description"})}
          </StyledText>
          <StyledText variant="body-medium">
            {intl.formatMessage({id: "feedback.question.one"})}
          </StyledText>

          <SendFeedBackForm
            taxonomy={taxonomy}
            handleSubmitSuccess={handleSubmitSuccess}
            isSuccess={thumbs.success}
          />
        </>
      ) : null}
    </div>
  )
}
