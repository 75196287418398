import { useEffect, useMemo } from "react";
import { getUserInformation, UserCredentials, userLogin, userSignup, UserSignUp } from "services/user.service";
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { shallow } from 'zustand/shallow'

export enum PROCESS_STATE {
  IDLE, EXECUTING, SUCCESS, FAILED
}

export interface StoreAsyncProcess<D = any> {
  state: PROCESS_STATE,
  data?: D
}

export interface AsyncAction<S extends StoreAsyncProcess> {
  execute: (...params: any) => Promise<any>
  clear: () => void
  process: S
}

export interface UserData {
  email?: string;
  first_name?: string
  last_name?: string;
  referral_id?: string;
  isActive?: boolean;
}

interface UserStoreI {
  session: { authenticated: boolean, access_token?: string }
  userData: UserData;

  login_execute: (credentials: UserCredentials, handleSuccess: () => Promise<void>) => Promise<void>;
  login_process: StoreAsyncProcess
  login_clear: () => any

  signup_execute: (user: UserSignUp) => Promise<void>
  signup_clear: () => any
  signup_process: StoreAsyncProcess

  logout: () => Promise<void>;
}


export const useUserStore = create<UserStoreI>()(
  devtools(
    persist(
      (set) => ({
        session: { authenticated: false },
        userData: { name: "", tier: "", email: "", first_name: "", last_name: "", referral_id: "", isActive: false },
        signup_process: { state: PROCESS_STATE.IDLE },
        signup_clear: () => set({ signup_process: { state: PROCESS_STATE.IDLE } }),
        signup_execute: async (user: UserSignUp) => {
          set({ signup_process: { state: PROCESS_STATE.EXECUTING } })
          try {
            await userSignup(user)
            set({ signup_process: { state: PROCESS_STATE.SUCCESS } })
          } catch (e: any) {
            set({ signup_process: { state: PROCESS_STATE.FAILED, data: e.response?.status ?? "" } })
            console.log(e)
          }
        },
        login_process: { state: PROCESS_STATE.IDLE },
        login_clear: () => set({ login_process: { state: PROCESS_STATE.IDLE } }),
        login_execute: async (credentials: UserCredentials, handleSuccess: () => Promise<void>) => {
          set({ login_process: { state: PROCESS_STATE.EXECUTING } })
          try {
            const data = await userLogin(credentials)
            set({ session: { authenticated: true, access_token: data.access_token } })
            const userInfoData = await getUserInformation()
            set({
              userData: {
                email: userInfoData.email,
                first_name: userInfoData.first_name,
                isActive: userInfoData.is_active,
                last_name: userInfoData.last_name,
                referral_id: userInfoData.referral_id,
              }
            })
            await handleSuccess()
            set({ login_process: { state: PROCESS_STATE.SUCCESS } })
          } catch (e: any) {
            set({ login_process: { state: PROCESS_STATE.FAILED, data: e.response.status } })
          }
        },
        logout: async () => {
          set(() => ({
            session: {
              authenticated: false,
              access_token: undefined
            },
            userData: {
              email: "",
              first_name: "",
              isActive: false,
              last_name: "",
              referral_id: ""
            }
          }))
        },
      }),
      {
        name: "user-storage"
      }
    )
  )
);

export const useIsAuthenticated = () => useUserStore((state) => state.session.authenticated)

export const useUserSignup = () => {
  const { signup, process, clear } = useUserStore(
    (state) => ({ signup: state.signup_execute, process: state.signup_process, clear: state.signup_clear }),
    shallow
  )
  useEffect(() => () => { clear() }, [clear])
  return useMemo(() => ({ signup, process }), [signup, process])
}

export const useUserLogin = () => {
  const { login, process, clear } = useUserStore(
    (state) => ({ login: state.login_execute, process: state.login_process, clear: state.login_clear }),
    shallow
  )
  useEffect(() => () => { clear() }, [clear])
  return useMemo(() => ({ login, process }), [login, process])
}

