import { StyledText } from "components/Atoms/StyledText/StyledText"
import {
  StyledRadioButton,
  RadioItem,
} from "components/Atoms/StyledRadioButton/StyledRadioButton"
import React from "react"
import "./styles.scss"
import { useIntl } from "react-intl"
export type taxonomyValueType = "original" | "revised"
interface TaxonomyVersionProps {
  onChange: React.Dispatch<React.SetStateAction<taxonomyValueType>>
  value: taxonomyValueType
}

export const TaxonomyVersion = ({
  onChange,
  value = "revised",
}: TaxonomyVersionProps) => {
  const intl = useIntl()
  const options: RadioItem[] = [
    { value: "original", label: "taxonomy.version.original" },
    { value: "revised", label: "taxonomy.version.revised" },
  ]

  return (
    <div className="taxonomy-version-container">
      <StyledText variant="title-medium">
        {intl.formatMessage({ id: "taxonomy.version.title" })}
      </StyledText>
      <div className="taxonomy-version-options-container">
        <StyledRadioButton
          value={value as taxonomyValueType}
          radioData={options}
          onChange={(e) => onChange(e.target.value as taxonomyValueType)}
        />
      </div>
    </div>
  )
}
