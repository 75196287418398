import { ROUTES } from "../../constants";
import { Navigate, Outlet } from "react-router-dom";
import { useIsAuthenticated } from "store/UserStore";

export const PrivateRoute = ({ children, inverse = false }: any) => {
  const authorized = useIsAuthenticated();
  const showComponent = (authorized && !inverse) || (!authorized && inverse);
  const redirection = inverse ? ROUTES.HOME : ROUTES.LOGIN;


  if (!showComponent) {
    return <Navigate to={redirection} />;
  } else return children ? children : <Outlet />;
};
