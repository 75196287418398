export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/signup",
  CUSTOMIZE: "/customize-categories",
  RECOVERPASSWORD: "/recover_password",
  NEWPASSWORD: "/password_reset",
  TIERSANDREFERRALS: "/my-tier",
};

export const REGEXP_VALIDATIONS = {
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
};

export const API_HOST = `${process.env.REACT_APP_APIHOST}`;
