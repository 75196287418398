

import Enflux from "services";

export interface TiersDataResponse {
  data: {
    is_unlimited_predictions: boolean;
    referral_bonus_diff_domain: number;
    referral_bonus_same_domain: number;
    can_batch: boolean;
    display_name: string;
    can_download: boolean;
  }
}

export interface UsageOfTIersResponse {
  data: {
    available_predictions: number,
    referral_count: number,
    used_predictions: number
  }
}

export interface TiersInformationResponse {
    display_name: string,
    tier_id: string,
    default_predictions: number,
    description: string,
    can_batch: boolean,
    can_download: boolean,
    created_at: string,
    bullet_points: string[],
    referral_bonus_same_domain: number,
    referral_bonus_diff_domain: number,
    is_current: boolean,
    updated_at: string
}


export interface TiersInformationResponse {
  data: TiersInformationResponse[]
}



export const getTierInformationMe = async (): Promise<TiersDataResponse> => {
  return await Enflux.get("/tiers/me")

}

export const getUsageOfTiers = async (): Promise<UsageOfTIersResponse> => {
  return await Enflux.get("/usage/me")
}

export const getTierInformationGeneral = async (): Promise<TiersInformationResponse> => {
  return await Enflux.get("/tiers")
}
