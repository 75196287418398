import { Navigate, Route, Routes } from "react-router-dom"
import { ROUTES } from "./constants"
import { PrivateRoute } from "components/PrivateRoute"
import { HomeScreen } from "screens/HomeScreen"
import { RegistrationScreen } from "screens/RegistrationScreen"
import { LoginScreen } from "screens/LoginScreen"
import { RecoverPasswordScreen } from "screens/RecoverPasswordScreen"
import { NewPasswordScreen } from "screens/NewPasswordScreen"
import { HeaderLayout } from "components/Molecules/Layout/HeaderLayout/HeaderLayout"
import { BloomsCustomizeCategoriesScreen } from "screens/BloomsCustomizeCategoriesScreen"
import { TiersReferralsScreen } from "screens/TiersReferralsScreen"
import { ExpiredSessionModal } from "components/Molecules/ExpiredSessionModal/ExpiredSessionModal"
import { GenericErrorModal } from "components/Molecules/GenericErrorModal/GenericErrorModal"

function App() {
  return (
    <>
      <GenericErrorModal />
      <ExpiredSessionModal />
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<HeaderLayout />}>
            {/* Private routes when user is not logged */}
            <Route index element={<HomeScreen />} path={ROUTES.HOME} />
            <Route
              element={<BloomsCustomizeCategoriesScreen />}
              path={ROUTES.CUSTOMIZE}
            />
            <Route
              element={<TiersReferralsScreen />}
              path={ROUTES.TIERSANDREFERRALS}
            />
          </Route>
        </Route>
        <Route element={<PrivateRoute inverse />}>
          {/* Private routes when user is logged */}
          <Route element={<RegistrationScreen />} path={ROUTES.REGISTER} />
          <Route
            element={<RecoverPasswordScreen />}
            path={ROUTES.RECOVERPASSWORD}
          />
          <Route element={<LoginScreen />} path={ROUTES.LOGIN} />
          <Route element={<NewPasswordScreen />} path={ROUTES.NEWPASSWORD} />
        </Route>
        <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />

      </Routes>
    </>
  )
}

export default App
