import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import English from "libs/i18n/en-US.json";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";

interface LocaleConfig {
  [key: string]: any;
}
const messages: LocaleConfig = English;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <IntlProvider locale="en-US" messages={messages}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </IntlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
