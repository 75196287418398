import { ColorSchemaType } from "./types";

export const getColor = (color?: ColorSchemaType) => {
  if (!color) return "N/A";
  switch (color) {
    case "primary100":
      return "#F8983A";
    case "primary80":
      return "#FFAF51";
    case "primary50":
      return "#FFC566";
    case "primary20":
      return "#FFDC7B";
    case "primary10":
      return "#FFF5DB";
    case "secondary100":
      return "#973794";
    case "secondary80":
      return "#C361BE";
    case "secondary50":
      return "#F28CEC";
    case "secondary30":
      return "#FFCFFF";
    case "secondary20":
      return "#F8E8F8";
    case "secondary10":
      return "#FCF5FC";
    case "grey100":
      return "#333333";
    case "grey50":
      return "#5E5E5E";
    case "grey20":
      return "#B0B0B0";
    case "grey10":
      return "#E2E2E2";
    case "natural-black":
      return "#111111";
    case "natural-white":
      return "#FCFCFC";
    case "red":
      return "#FE3A4F";
    case "green":
      return "#1B984B";
    case "yellow":
      return "#F6E017";
    default:
  }
};
