import React from "react";
import { useForm } from "react-hook-form";
import Form from "components/Atoms/Form";
import { withControllerInput } from "utils/hocs/withControllerInput";
import { StyledTextField } from "components/Atoms/StyledTextField";
import "./styles.scss";
import { StyledChips } from "components/Atoms/StyledChips/StyledChips";
import { capitalizeFirstLetter } from "utils/helpers/app.helper";
import { StyledButton } from "components/Atoms/StyledButton/StyledButton";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { usePredictsStore, useSendFeedback } from "store/PredictTextStore";
import { PROCESS_STATE } from "store/UserStore";
import { useIntl } from "react-intl";
const chipsToRenderLegacy = [
  "knowledge",
  "comprehension",
  "application",
  "analysis",
  "synthesis",
  "evaluation",
];
const chipsToRenderRevised = [
  "remember",
  "understand",
  "apply",
  "analyze",
  "evaluate",
  "create",
];

interface SendFeedBackFormProps {
  taxonomy: "original" | "revised";
  handleSubmitSuccess: (data: any, categorieValue: string) => void;
  isSuccess?: boolean;
}

const ControllerTextInput = withControllerInput(StyledTextField);
export const SendFeedBackForm = ({
  taxonomy,
  handleSubmitSuccess,
  isSuccess,
}: SendFeedBackFormProps) => {
  const intl = useIntl();
  const { process } = useSendFeedback();
  const {
    categoryLegacy,
    categoryRevised,
    setCategoryLegacy_execute,
    setCategoryRevised_execute,
    predictionLegacy_mapped,
    predictionRevised_mapped,
  } = usePredictsStore();

  const methods = useForm({ mode: "onChange" });
  const { control } = methods;
  const categories =
    taxonomy === "original" ? chipsToRenderLegacy : chipsToRenderRevised;
  const categorieValue =
    taxonomy === "original" ? categoryLegacy : categoryRevised;
  const setCategorieValue =
    taxonomy === "original"
      ? setCategoryLegacy_execute
      : setCategoryRevised_execute;

  const predictionMapped =
    taxonomy === "original"
      ? predictionLegacy_mapped
      : predictionRevised_mapped;
  return (
    <Form
      methods={methods}
      onSubmit={(data) => handleSubmitSuccess(data, categorieValue)}
      className="form-feedback-container"
    >
      <div className="chips-container">
        {categories.map(
          (categorie, index) =>
            predictionMapped !== categorie && (
              <StyledChips
                key={index}
                labelId={capitalizeFirstLetter(categorie)}
                onClick={() => !isSuccess && setCategorieValue(categorie)}
                className={categorieValue === categorie ? "chip-selected" : ""}
              />
            )
        )}
      </div>
      <StyledText variant="body-medium">
        {intl.formatMessage({ id: "feedback.question.two" })}
      </StyledText>
      <ControllerTextInput
        name="feedbackText"
        id="feedback-text"
        label="feedback.text.field.placeholder"
        control={control}
        showMaxlengthCounter
        maxLength={20}
        fullWidth
      />
      <div className={`button-message-container ${isSuccess ? "success" : ""}`}>
        {isSuccess && (
          <div className="thankYouContainer">
            <StyledText>
              {intl.formatMessage({ id: "feedback.text.footer" })}
            </StyledText>
          </div>
        )}
        <StyledButton
          isLoading={process.state === PROCESS_STATE.EXECUTING}
          buttonVariant="contained"
          size="large"
          title="feedback.send.feedback.button"
          isSubmbit
          isDisabled={categorieValue === "" || isSuccess}
        />
      </div>
    </Form>
  );
};
