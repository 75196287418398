import Img from "components/Atoms/Img"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import {
  RegisterForm,
  RegisterFormModel,
} from "components/Molecules/Forms/RegisterForm"
import { ROUTES } from "../../constants"
import { useCallback, useMemo } from "react"
import "./style.scss"
import { PROCESS_STATE, useUserSignup } from "store/UserStore"
import { Navigate } from "react-router-dom"
import { useIntl } from "react-intl"
import { StyledButton } from "components/Atoms/StyledButton/StyledButton"
import { useNavigate } from "react-router-dom"


export interface RegistrationContainerProps {
  title: string
}

export const RegistrationContainer = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { signup, process } = useUserSignup()

  const redirect = useMemo(() => {
    if (process.state === PROCESS_STATE.SUCCESS) {
      return ROUTES.LOGIN
    }
    return undefined
  }, [process])

  const onRegister = useCallback(
    (data: RegisterFormModel) => {
      signup(data)
    },
    [signup]
  )

  return (
    <main className="register-container">
      <Img src={"/images/main_logo.png"} alt="logo" className="imgP-2" />
      <StyledText variant="title-large" align="center" color="primary100">
        {intl.formatMessage({ id: "screen.createAccount" })}
      </StyledText>
      <RegisterForm onClick={onRegister} />

      <StyledButton
        title={"already.have.an.account"}
        buttonVariant="text"
        size="medium"
        onClick={() => navigate(ROUTES.LOGIN)}
      />

      {redirect && <Navigate to={redirect} replace />}
    </main>
  )
}
