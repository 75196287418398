import React from "react";
import { SvgIcon, Tab, Tabs } from "@mui/material";
import { useIntl } from "react-intl";
import "./styles.scss";

export interface TabOption {
  isDisabled?: boolean;
  icon?: React.ReactNode;
  labelId: string;
  value: string;
  onClick?: () => void;
}
interface StyledTabProps {
  options: TabOption[];
  currentValue: string;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
}
export const StyledTab = ({
  options,
  currentValue,
  onChange,
  fullWidth,
}: StyledTabProps) => {
  const intl = useIntl();
  return (
    <div className="tab-container">
      <Tabs
        value={currentValue}
        onChange={onChange}
        aria-label="wrapped label tabs example"
        variant={fullWidth ? "fullWidth" : "standard"}
      >
        {options.map((e, index) => (
          <Tab
            disableRipple
            key={index}
            tabIndex={index}
            value={e.value}
            onClick={e.onClick}
            iconPosition="start"
            icon={
              e.icon ? (
                <SvgIcon data-testid="svg-icon">{e.icon}</SvgIcon>
              ) : undefined
            }
            disabled={e.isDisabled}
            label={intl.formatMessage({ id: e.labelId })}
          />
        ))}
      </Tabs>
    </div>
  );
};
