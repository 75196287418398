import { Box } from "@mui/system";
import React from "react";
import "./styles.scss";

interface ElevationsProps {
  variant: VariantsElevations;
  children: React.ReactNode;
  className?: string;
}

type VariantsElevations = "rectangle-1" | "rectangle-2" | "rectangle-3";

export const Elevations = ({
  variant,
  children,
  className,
}: ElevationsProps) => {
  return <Box className={`${variant} ${className}`}>{children}</Box>;
};
