import { Modal } from "@mui/material"
import React, { ReactElement } from "react"
import { StyledText } from "../StyledText/StyledText"
import { X } from "phosphor-react"
import { getColor } from "theme/colors"
import "./style.scss"

interface StyledModalProps {
  open: boolean
  onClose: () => void
  children: ReactElement
  title?: string
  width?: string
  withXButton?: boolean
}

export const StyledModal = ({
  open,
  onClose,
  children,
  title,
  width,
  withXButton,
}: StyledModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="styledModal" style={{ width: width }}>
        <div className={`modalTitle ${withXButton ? "withX" : ""}`}>
          <StyledText variant="title-large">{title ?? ""}</StyledText>
          {withXButton && (
            <X
              size={25}
              color={getColor("primary100")}
              className="hoverElement"
              onClick={onClose}
            />
          )}
        </div>
        {children}
      </div>
    </Modal>
  )
}
