import { Header } from "components/Molecules/Header"
import React from "react"
import { Outlet } from "react-router-dom"
import "./styles.scss"
export const HeaderLayout = () => {
  return (
    <>
      <div className="header-container">
        <Header />
      </div>
      <main className="layout-container">
        <Outlet />
      </main>
    </>
  )
}
