import { StyledText } from "components/Atoms/StyledText/StyledText";
import { CheckCircle, FloppyDisk, WarningCircle } from "phosphor-react";
import { useIntl } from "react-intl";
import { PROCESS_STATE } from "store/UserStore";
import { getColor } from "theme/colors";

interface ChangesSavedConfirmProps {
  state: PROCESS_STATE;
}

export const ChangesSavedConfirm = ({ state }: ChangesSavedConfirmProps) => {
  const intl = useIntl();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {state === PROCESS_STATE.EXECUTING ? (
        <FloppyDisk
          size={18}
          color={getColor("secondary100")}
          style={{ marginRight: "0.3rem" }}
        />
      ) : state === PROCESS_STATE.FAILED ? (
        <WarningCircle
          size={18}
          color={getColor("red")}
          style={{ marginRight: "0.3rem" }}
        />
      ) : (
        (state === PROCESS_STATE.IDLE || PROCESS_STATE.SUCCESS) && (
          <CheckCircle
            size={18}
            color={getColor("green")}
            style={{ marginRight: "0.3rem" }}
          />
        )
      )}
      {state === PROCESS_STATE.FAILED ? (
        <StyledText variant="body-small" color="grey50">
          {intl.formatMessage({ id: "changes.error" })}
        </StyledText>
      ) : (
        <StyledText variant="body-small" color="grey50">
          {intl.formatMessage({ id: "changes.saved" })}
        </StyledText>
      )}
    </div>
  );
};
