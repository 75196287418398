import React, { useMemo } from "react"
import "theme/_variables.scss"
import "./styles.scss"
import { ColorSchemaType } from "theme/types"
import { Link, To } from "react-router-dom"

export type AlignType = "left" | "center" | "right"

export type VariantTypes =
  | "title-large"
  | "title-medium"
  | "title-small"
  | "body-large"
  | "body-medium"
  | "body-small"
  | "label-large"
  | "label-medium"
  | "label-small"

export interface StyledTextProps {
  variant?: VariantTypes
  children?: string | number | React.ReactElement
  align?: AlignType
  color?: ColorSchemaType
  className?: String
  link?: boolean
  To?: To
  underline?: boolean
  style?: React.CSSProperties
}

export const StyledText = ({
  children,
  align = "left",
  color = "natural-black",
  className,
  variant = "body-medium",
  link,
  To,
  underline,
  style,
  ...rest
}: StyledTextProps) => {
  const node = {
    "title-large": "h1",
    "title-medium": "h2",
    "title-small": "h3",
    "body-large": "h4",
    "body-medium": "h5",
    "body-small": "h6",
    "label-large": "label",
    "label-medium": "p",
    "label-small": "small",
  }

  const cls = useMemo(() => {
    let classnames = `${color}`
    classnames += align ? ` text-${align}` : ""
    classnames += underline ? " underline" : ""
    return classnames.trim()
  }, [align, color, underline])

  return link ? (
    <Link
      style={style}
      className={`${node[variant]} ${cls} ${className ? className : ""}`}
      to={To !== undefined ? To : "/"}>
      {children}
    </Link>
  ) : (
    React.createElement(
      node[variant],
      {
        className: `${cls} ${className ? className : ""}`,
        style: style,
        ...rest,
      },
      children
    )
  )
}
