import React, { useCallback } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";

interface FormProps {
  children: React.ReactNode;
  onSubmit?: SubmitHandler<any>;
  applyReset?: boolean;
  methods: any;
  className?: string
}

const Form = ({
  children,
  onSubmit,
  applyReset = false,
  methods,
  className
}: FormProps) => {
  const { handleSubmit, reset } = methods;
  const submit = useCallback(
    async (data: any) => {
      try {
        onSubmit && (await onSubmit(data));
        if (applyReset) {
          reset();
        }
      } catch (error) {}
    },
    [onSubmit, applyReset, reset]
  );
  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={onSubmit && handleSubmit(submit)}>{children}</form>
    </FormProvider>
  );
};

export default Form;
