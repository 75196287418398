import { Elevations } from "components/Atoms/Elevations/Elevations";
import React from "react";
import "./styles.scss";

interface GenericContainerProps {
  children: React.ReactNode;
  className?: string;
}

export const GenericContainer = ({
  children,
  className,
}: GenericContainerProps) => {
  return (
    <div className="generic-container">
      <Elevations
        variant="rectangle-3"
        className={`generic-container-elevation ${className}`}
      >
        {children}
      </Elevations>
    </div>
  );
};
