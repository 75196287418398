import { PercentageCard } from "components/Atoms/PercentageCard/PercentageCard"
import React from "react"
import { capitalizeFirstLetter } from "utils/helpers/app.helper"
import './styles.scss'
export interface LegacyRevisedArray {
  name: string
  customName: string
  sum: number
  singleValue: number
}

interface PercentageCardsComponentProps {
  taxonomy: "original" | "revised"
  legacyData: LegacyRevisedArray[][]
  revisedData: LegacyRevisedArray[][]
  showLegacyCards: boolean
  showRevisedCards: boolean
}

export const PercentageCardsComponent = ({
  taxonomy,
  legacyData,
  revisedData,
  showLegacyCards,
  showRevisedCards,
}: PercentageCardsComponentProps) => {
  return (
    <div className="percentage-cards-container">
      {taxonomy === "original" && legacyData && showLegacyCards
        ? legacyData?.map((name: any, index: number) => {
            return (
              <PercentageCard
                title={`${
                  name[0].customName !== null
                    ? capitalizeFirstLetter(name[0].customName)
                    : capitalizeFirstLetter(name[0].name)
                }: ${
                  name[0].customName !== name[0].name
                    ? parseFloat(name[0].total).toFixed(2)
                    : parseFloat(name[0].singleValue).toFixed(2)
                }`}
                taxonomy={taxonomy}
                key={index}
                categoryName={capitalizeFirstLetter(name[0]?.name) ?? ""}
                customName={name[0].customName}
                percentage={name[0].singleValue ?? 0}
              />
            )
          })
        : revisedData !== null &&
          showRevisedCards &&
          revisedData?.map((name: any, index: number) => {
            return (
              <PercentageCard
                title={`${
                  name[0].customName !== null
                    ? capitalizeFirstLetter(name[0].customName)
                    : capitalizeFirstLetter(name[0].name)
                }: ${
                  name[0].customName !== name[0].name
                    ? parseFloat(name[0].total).toFixed(2)
                    : parseFloat(name[0].singleValue).toFixed(2)
                }`}
                taxonomy={taxonomy}
                key={index}
                categoryName={capitalizeFirstLetter(name[0]?.name)}
                customName={name[0].customName}
                percentage={name[0]?.singleValue ?? 0}
              />
            )
          })}
    </div>
  )
}
