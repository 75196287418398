import { GoBack } from "components/Atoms/GoBack";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { StyledTab, TabOption } from "components/Atoms/Tab/StyledTab";
import { GenericContainer } from "components/Molecules/GenericContainer";
import { ROUTES } from "../../constants";
import { useState } from "react";
import { useIntl } from "react-intl";
import { DnDCustomizeCategories } from "components/Molecules/DnDCustomizeCategories";

export const BloomsCustomizeCategoriesContainer = () => {
  const intl = useIntl();
  const [taxonomy, setTaxonomy] = useState<"original" | "revised">("original");
  const options: TabOption[] = [
    {
      value: "original",
      labelId: "tab.original.taxonomy",
    },
    {
      value: "revised",
      labelId: "tab.revised.taxonomy",
    },
  ];
  return (
    <GenericContainer className="bodyWMin">
      <GoBack
        color="primary100"
        stringText="back.classifier"
        to={ROUTES.HOME}
      />
      <StyledText align="left" variant="title-large">
        {intl.formatMessage({ id: "blooms.customize.title" })}
      </StyledText>
      <StyledText align="left" variant="body-large">
        {intl.formatMessage({ id: "drag.and.drop.categories" })}
      </StyledText>
      <StyledTab
        currentValue={taxonomy}
        onChange={() => {
          setTaxonomy(taxonomy === "original" ? "revised" : "original");
        }}
        options={options}
        fullWidth
      />
      <DnDCustomizeCategories taxonomy={taxonomy} />
    </GenericContainer>
  );
};
