import { PencilSimpleLine } from "phosphor-react";
import { useEffect, useState } from "react";
import { getColor } from "theme/colors";
import "./styles.scss";

interface InputChipsProps {
  defaultValue?: string;
  onChange?: (e?: any) => void;
  disabled?: boolean;
  id: string;
}

export const InputChips = ({
  defaultValue,
  onChange,
  disabled,
  id,
}: InputChipsProps) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? "");

  useEffect(() => {
    setInputValue(defaultValue ?? "");
  }, [defaultValue]);

  useEffect(() => {
    if (inputValue !== defaultValue) {
      const debounceTimeout = setTimeout(() => {
        onChange && inputValue !== "" && onChange(inputValue);
      }, 350);
      return () => clearTimeout(debounceTimeout);
    }
  }, [inputValue, onChange, defaultValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="inputChipContainer">
      <input
        className="inputChip"
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
        id={id}
      />
      <PencilSimpleLine size={20} color={getColor("natural-white")} />
    </div>
  );
};
