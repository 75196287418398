import Enflux from "services";

export interface UserSignUp {
    email: string;
    first_name: string;
    last_name: string;
    password: string;
    university: string;
    phone_number: string;
    title: string;
    referral_id?: string;
}

export interface UserCredentials {
    email: string;
    password: string;
}

export interface AuthenticationResponse {
    access_token: string;
    token_type: string;
}

export interface UserDataResponse {
    email: string;
    first_name: string
    last_name: string;
    tier: string;
    referral_id: string;
    is_active: boolean;
}

export const userSignup = async (user: UserSignUp) => {
    const request = await Enflux.post("/users/", {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        password: user.password,
        university: user.university,
        phone_number: user.phone_number,
        title: user.title,
        referral_id: user.referral_id ?? "",
    });
    return request.data;
};

export const userLogin = async (
    credentials: UserCredentials
): Promise<AuthenticationResponse> => {
    const request = await Enflux.post("/auth/login", credentials);
    return request.data;
};

export const getUserInformation = async (): Promise<UserDataResponse> => {
    const { data } = await Enflux.get("/users/me")
    return data
}
