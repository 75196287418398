import React from "react"
import { StyledText } from "../StyledText/StyledText"
import "./styles.scss"
import { CircularProgress, Tooltip } from "@mui/material"

export interface PercentageCardProps {
  percentage: number
  categoryName: string
  customName: string
  taxonomy: "original" | "revised",
  title: string
}

const normalizePercentage = (value: number) => {
  return value * 100
}
export const PercentageCard = ({
  categoryName,
  customName,
  percentage,
  taxonomy,
  title
}: PercentageCardProps) => {


  const val = (Math.floor(100 * percentage) / 100).toFixed(1)
  return (
    <Tooltip
      placement="top"
      title={title}>
      <div className="percentage-card-container">
        <div className="percentage-circle-container">
          <div className="shadow-circle"></div>
          <CircularProgress
            variant="determinate"
            value={normalizePercentage(percentage)}
          />
          <div className="percentage-number">
            <StyledText variant="title-large">{`${val}` ?? "0.0"}</StyledText>
          </div>
        </div>
        <StyledText>{categoryName}</StyledText>
        <StyledText>{customName ? `(${customName.substring(0,15)}${customName.length > 15 ? "..." : ""})` : ""}</StyledText>
      </div>
    </Tooltip>
  )
}
