
import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

interface ModalStoreI {
  valueSessionExpireModal: boolean
  setBooleanSessionExpiredModal: (open: boolean) => void

  valueGenericErrorModal: boolean
  errorCodeGenericErrorModal: string
  messageErrorGenericErrorModal: string
  setBooleanGenericErrorModal: (open: boolean, message: string, errorCode: string) => void
  setCloseGenericErrorModal: (open: boolean) => void
  cleanGenericModal: () => void
}
export const useModalStore = create<ModalStoreI>()(devtools(persist(
  (set, get) => ({
    valueSessionExpireModal: false,
    setBooleanSessionExpiredModal(open) {
      set({ valueSessionExpireModal: open })
    },
    valueGenericErrorModal: false,
    messageErrorGenericErrorModal: "",
    errorCodeGenericErrorModal: "",
    setBooleanGenericErrorModal(open, message, errorCode) {
      set({ valueGenericErrorModal: open })
      set({ errorCodeGenericErrorModal: errorCode })
      set({ messageErrorGenericErrorModal: message })
    },
    setCloseGenericErrorModal(open) {
      set({ valueGenericErrorModal: open })
    },
    cleanGenericModal: () => {
      set({valueGenericErrorModal: false})
      set({messageErrorGenericErrorModal: ""})
      set({errorCodeGenericErrorModal: ""})
    }
  }),
  {
    name: "modal-storage",
  }
)))