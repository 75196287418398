import { Elevations } from "components/Atoms/Elevations/Elevations"
import { CheckCircle } from "phosphor-react"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import React from "react"
import "./styles.scss"
import { getColor } from "theme/colors"
import { StyledButton } from "components/Atoms/StyledButton/StyledButton"
import { useTierReferralsStore } from "store/TierReferralsStore"
import { capitalizeFirstLetter } from "utils/helpers/app.helper"

interface TierCardsProps {
  indexActive: number
}

const renderBulletPoints = (bullet_points: string[]) => {
  return (
    <div>
      <ul className="bullet-points">
        {bullet_points.map((bullet: string) => {
          return (
            <li key={bullet}>
              <StyledText
                variant="body-small"
                align="left"
                color="natural-black">
                {capitalizeFirstLetter(bullet)}
              </StyledText>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export const TierCards = ({ indexActive }: TierCardsProps) => {
  const { tierDataInformation } = useTierReferralsStore()

  const formatDescription = (description: string) => {
    return description.replace(/<br>/g, "\n")
  }

  return (
    <div className="tier-cards-container">
      <Elevations
        variant="rectangle-2"
        className={`tier-card-container ${
          indexActive === 1 ? "selected" : ""
        }`}>
        <div className="tier-card-1-title">
          <StyledText align="center" variant="title-medium" color="primary100">
            {tierDataInformation[0].display_name}
          </StyledText>
          {indexActive === 1 && (
            <CheckCircle
              color={getColor("primary100")}
              weight="fill"
              size={20}
            />
          )}
        </div>
        <div className="tier-card-1-description-container">
          <div className="tier-card-1-subtitle-container">
            <StyledText variant="body-small" color="natural-black" align="left">
              {formatDescription(tierDataInformation[0].description)}
            </StyledText>
          </div>
          <StyledText variant="body-small" color="natural-black" align="left">
            {renderBulletPoints(tierDataInformation[0].bullet_points)}
          </StyledText>
        </div>
      </Elevations>

      <Elevations
        variant="rectangle-2"
        className={`tier-card-container ${
          indexActive === 2 ? "selected" : ""
        }`}>
        <div className="tier-card-2-title">
          <StyledText
            align="center"
            variant="title-medium"
            color="natural-white">
            {tierDataInformation[1].display_name}
          </StyledText>
          {indexActive === 2 && (
            <CheckCircle
              color={getColor("natural-white")}
              weight="fill"
              size={20}
            />
          )}
        </div>
        <div className="tier-card-2-description-container">
          <div className="tier-card-1-subtitle-container">
            <StyledText
              variant="body-small"
              color="natural-black"
              align="left"
              style={{ whiteSpace: "pre-line" }}>
              {formatDescription(tierDataInformation[1].description)}
            </StyledText>
          </div>
          <div className="description-button-container">
            {renderBulletPoints(tierDataInformation[1].bullet_points)}
            {indexActive !== 3 && (
              <div className="button-tier-container">
                {indexActive === 2 ? (
                    null
                ) : (
                  <a
                    href={"https://meetings.hubspot.com/rickylopez/competency-genie-qualifier-meeting"}
                    target="_blank"
                    rel="noreferrer">
                    <StyledButton
                      size="medium"
                      buttonVariant="contained"
                      isDisabled={indexActive === 2}
                      title={"tier.card.button.1"}
                    />
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </Elevations>

      <Elevations
        variant="rectangle-2"
        className={`tier-card-container ${
          indexActive === 3 ? "selected" : ""
        }`}>
        <div className="tier-card-3-title">
          <StyledText
            align="center"
            variant="title-medium"
            color="natural-white">
            {tierDataInformation[2].display_name}
          </StyledText>
          {indexActive === 3 && (
            <CheckCircle
              color={getColor("natural-white")}
              weight="fill"
              size={20}
            />
          )}
        </div>
        <div className="tier-card-2-description-container">
          <div className="tier-card-1-subtitle-container">
            <StyledText
              variant="body-small"
              color="natural-black"
              align="left"
              style={{ whiteSpace: "pre-line" }}>
              {formatDescription(tierDataInformation[2].description)}
            </StyledText>
          </div>
          <div className="description-button-container">
            {renderBulletPoints(tierDataInformation[2].bullet_points)}
            <div className="button-tier-container">
              {indexActive === 3 ? (
                  null
              ) : (
                <a
                  href={"https://meetings.hubspot.com/rickylopez/competency-genie-qualifier-meeting"}
                  target="_blank"
                  rel="noreferrer">
                  <StyledButton
                    size="medium"
                    buttonVariant="contained"
                    isDisabled={indexActive === 3}
                    title={"tier.card.button.1"}
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </Elevations>
    </div>
  )
}
