import { StyledModal } from "components/Atoms/StyledModal";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { useIntl } from "react-intl";
import "./style.scss";
import Img from "components/Atoms/Img";

interface FileSpecsModalProps {
  open: boolean;
  onClose: () => void;
}

export const FileSpecsModal = ({ open, onClose }: FileSpecsModalProps) => {
  const intl = useIntl();
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      title={intl.formatMessage({ id: "file.specs.modal.title" })}
      width="700px"
      withXButton
    >
      <>
        <StyledText>
          {intl.formatMessage({ id: "file.specs.modal.intructions" })}
        </StyledText>
        <div className="listItem">
          <div className="numberList">
            <StyledText color="natural-white" variant="label-large">
              1
            </StyledText>
          </div>
          <StyledText>
            {intl.formatMessage({ id: "file.specs.modal.first.step" })}
          </StyledText>
        </div>
        <Img src={"/images/step2.png"} alt="step 2" className="stepImage" />
        <div className="listItem">
          <div className="numberList">
            <StyledText color="natural-white" variant="label-large">
              2
            </StyledText>
          </div>
          <StyledText>
            {intl.formatMessage({ id: "file.specs.modal.second.step" })}
          </StyledText>
        </div>
        <Img src={"/images/step3.png"} alt="step 3" className="stepImage" />
        <div className="listItem">
          <div className="numberList">
            <StyledText color="natural-white" variant="label-large">
              3
            </StyledText>
          </div>
          <StyledText>
            {intl.formatMessage({ id: "file.specs.modal.third.step" })}
          </StyledText>
        </div>
      </>
    </StyledModal>
  );
};
