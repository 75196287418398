import axios, { InternalAxiosRequestConfig } from 'axios';
import { useModalStore } from "store/ModalStore";
import { useUserStore } from "store/UserStore";

const Enflux = axios.create({
    baseURL: process.env.REACT_APP_APIHOST,
    responseType: "json",
    headers: {
        "Content-Type": "application/json",
    }
})

Enflux.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
        const { access_token } = useUserStore.getState().session
        if (access_token && access_token !== "" && config.headers) {
            config.headers.Authorization = `Bearer ${access_token}`
        }
        return config
    },
    function (error: any) {
        // Do something with request error
        return Promise.reject(error)
    }
)



Enflux.interceptors.response.use(undefined, function (error: any) {
    const { access_token } = useUserStore.getState().session

    switch (error.response.status) {
        case 401:
            access_token && useModalStore.getState().setBooleanSessionExpiredModal(true)
            break;
        case 404:
            access_token && useModalStore.getState().setBooleanGenericErrorModal(true, "generic.error.modal.description.2", "404")
            break;
        case 500:
            useModalStore.getState().setBooleanGenericErrorModal(true, "generic.error.modal.description.1", "500")
            break;
        default:
            useModalStore.getState().setBooleanGenericErrorModal(true, "generic.error.modal.description.3", "400")
            break;

    }


    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
})

export default Enflux


